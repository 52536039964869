import React, { useState } from "react";

// Hooks
import { useSelector } from "react-redux";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import SwitchBackModal from "./SwitchBackModal";

// Mui Components
import { Box, Typography } from "@mui/material";

// styles
import "./styles.scss";

function SwitchBar() {
  const [openBackModal, setOpenBackModal] = useState(false);

  const userName = useSelector(
    (state) => state.userReducers?.user?.fullName || ""
  );

  return (
    <>
      <Box className="switchBar">
        <Typography>
          {`${MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.HEADER.TITLE} ${userName}.`}
          &nbsp;
          <span
            onClick={() => {
              setOpenBackModal(true);
            }}
          >
            {MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.HEADER.SUB_HEADING}
          </span>
        </Typography>
      </Box>
      <SwitchBackModal
        open={openBackModal}
        handleClose={() => setOpenBackModal(false)}
      />
    </>
  );
}

export default SwitchBar;
