import React, { useEffect, useRef, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  fetchAdminOrders,
  fetchAdminOrdersFilters,
} from "~/redux/actions/admin-orders-actions";
import { CLEAR_ADMIN_ORDERS_FILTERS } from "~/redux/actions/action-types";

//Utils
import { DEFAULT_PAGINATION } from "~/utils/constants";
import { MESSAGES } from "~/utils/message";
import {
  balanceFormatter,
  numberWithComma,
} from "~/utils/helperFunctions";

// Components
import Table from "./Table";
import AdvanceFilters from "./Filters";
import ActionModal from "./ActionModal";
import DispatchNowModal from "./DispatchNowModal";
import NoAdminOrderScreen from "./NoAdminOrderScreen";

// MUI Components
import {
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
  Container,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Icons
import { ContactSearchCIcon, ActiveFilterCIcon, InActiveFilterCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const AdminOrders = () => {
  // Use the useMediaQuery hook to check the screen size
  const { batchId } = useParams();
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [dispatchActionModal, setDispatchModal] = useState(false);

  const childRef = useRef();
  const dispatch = useDispatch();

  const applyFilters = useSelector(
    (state) => state.adminOrdersReducers.applyFilters
  );

  const filters = useSelector((state) => state.adminOrdersReducers.filters);
  const filterSize = useSelector(
    (state) => state.adminOrdersReducers.filterSize
  );

  const totalOrders = useSelector(
    (state) => state.adminOrdersReducers.totalOrders
  );

  const adminOrders = useSelector(
    (state) => state.adminOrdersReducers.totalBatchesInDB
  );

  const totalRevenue = useSelector(
    (state) => state.adminOrdersReducers.totalRevenue
  );

  const statsCard = [
    { title: "No. of Orders", value: numberWithComma(totalOrders) },
    { title: "Total Revenue", value: balanceFormatter(totalRevenue) },
  ];

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getAdminOrders();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getAdminOrders = () => {
    let payload = {
      pagination,
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
      batchId: batchId ? batchId : null,
    };
    dispatch(fetchAdminOrders(payload));
  };

  useEffect(() => {
    setIsMounted(true);
    getAdminOrders();
    dispatch(fetchAdminOrdersFilters());
    return () => {
      dispatch({ type: CLEAR_ADMIN_ORDERS_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      getAdminOrders();
    }
  }, [pagination.page, pagination.pageSize, filterSize, batchId, filters]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getAdminOrders();
    }
  }, [search]);

  const noAdminOrders = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoAdminOrderScreen />
      </Container>
    );
  };

  if(adminOrders === 0 && adminOrders !== null) 
  {
    return  noAdminOrders();
  }

  return (
    <>
      <Container maxWidth="xxl">
        <div className="adminOrderMain">
          <Grid container>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="searchBtnWrapper"
            >
              <Box className="searchWrapper">
                <Input
                  className={`searchInput ${
                    searchApplied ? "searchApplied" : ""
                  }`}
                  type="text"
                  value={search}
                  onChange={(e) => {
                    if (e.target.value.length > 9) return;
                    setSearch(
                      e.target.value.replace(/\s/g, "").replace(/\D/g, "")
                    );
                  }}
                  onKeyDown={searchKeyDown}
                  placeholder={`Search by order ID, user ID ${
                    !batchId ? "or batch ID" : ""
                  }`}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      className="searchInputAdornment"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        className="searchIcon"
                        onClick={handleSearch}
                      >
                        <img src={ContactSearchCIcon} alt="search" />
                      </IconButton>
                      {searchApplied && (
                        <HighlightOffIcon
                          onClick={() => {
                            setSearch("");
                          }}
                          className="clearSerach"
                        />
                      )}
                    </InputAdornment>
                  }
                />
                <Box className="filterBtnWrapper">
                  <img
                    onClick={() => {
                      setOpenAdvanceFilters(true);
                    }}
                    className={
                      filterSize > 0
                        ? "filterImage activeFilter"
                        : "filterImage noFilter"
                    }
                    src={filterSize > 0 ? ActiveFilterCIcon : InActiveFilterCIcon}
                    alt="Filter"
                  />
                  {filterSize > 0 && (
                    <Typography className="filtersCounter">
                      {filterSize}
                    </Typography>
                  )}
                </Box>
                {filterSize > 0 && (
                  <Button
                    onClick={() => {
                      childRef.current.clearFilters();
                    }}
                    className="clearFilterBtn"
                  >
                    {MESSAGES.ADMIN_DASHBOARD.ORDERS.CLEAR_FILTER_BTN_TEXT}
                  </Button>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="searchBtnWrapper outer"
            >
              {statsCard.map((item, index) => (
                <Box key={index} className="infoBox">
                  <span>{item.title}</span>
                  <p>{item.value}</p>
                </Box>
              ))}
            </Grid>
          </Grid>
          <Table
            pagination={pagination}
            updatePagination={setPagination}
            setOpenActionModal={setOpenActionModal}
            setDispatchModal={setDispatchModal}
            setSelectedOrder={setSelectedOrder}
            searchApplied={searchApplied}
          />
          <AdvanceFilters
            open={openAdvanceFilters}
            handleCloseFilter={() => setOpenAdvanceFilters(false)}
            ref={childRef}
            updatePagination={setPagination}
          />
          <ActionModal
            openModal={openActionModal}
            handleCloseModal={() => setOpenActionModal(false)}
            selectedOrder={selectedOrder}
            getAdminOrders={getAdminOrders}
          />
          <DispatchNowModal
            openModal={dispatchActionModal}
            handleCloseModal={() => setDispatchModal(false)}
            selectedOrder={selectedOrder}
          />
        </div>
      </Container>
    </>
  );
};

export default AdminOrders;
