// Request util for api call
import request from "~/utils/request";

// Actions Types
import { SET_ADMIN_BATCHES_DATA, SET_ADMIN_BATCHES_FILTERS_LABELS, SET_ADMIN_BATCHES_LOADER } from "./action-types";

// Utiils
import { dateFormat } from "~/utils/date-format";
import { DEFAULT_PAGINATION } from "~/utils/constants";
import { balanceFormatter } from "~/utils/helperFunctions";


const fetchAdminBatches = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_ADMIN_BATCHES_LOADER, payload: true });

        let url = 'admin/batches';

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'createdAt') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            let date = Object?.entries(value)
                                .map(([nestedKey, nestedValue]) => `${JSON.stringify(nestedKey)}:${JSON.stringify(nestedValue)}`)
                                .join(', ');
                            queryPayload[key] = `{${date}}`;
                        }
                    }
                    else if (value.length) {
                        queryPayload[key] = `${JSON.stringify(value)}`;
                    }
                })
        }

        const response = await request.get(url, queryPayload);

        if (response.status === 200) {
            const combinedData = response.data.data.rows.map((batch, i) => ({
                key: i,
                batchId: batch.id,
                wooId: batch.wooOrderId,
                wooUrl: batch.wooUrl,
                ordersCount: batch.ordersCount,
                quantity: batch.csvFileRecordsCount,
                revenue: batch.cost ? balanceFormatter(batch.cost) : " -",
                createdAt: dateFormat(batch.createdAt, true),
                productType: batch.product['name'],
                accuzipStatus: batch.accuzipCsvStatus,
                batchStatus: batch.status,
            }));
            const batch = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
                totalRevenue: response.data.data.totalRevenue,
                totalQuantity: response.data.data.totalQuantity,
            }
            dispatch({ type: SET_ADMIN_BATCHES_DATA, payload: batch });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ADMIN_BATCHES_LOADER, payload: false });
    }
}

const fetchAdminBatchFilters = () => async (dispatch) => {
    try {
        const filters = await request.get("admin/batches/filters-data");
        let modifiedLabels = {}
        Object.keys(filters.data.data).forEach((key) => {
            if (typeof filters.data.data[key] === 'object') {
                const combinedData = Object.entries(filters.data.data[key]).map(([valueKey, item], id) => ({
                    id,
                    title: item,
                    value: valueKey || item,
                }));
                modifiedLabels[key] = combinedData;
            }

        });
        dispatch({ type: SET_ADMIN_BATCHES_FILTERS_LABELS, payload: modifiedLabels });
    } catch (error) {
        return error;
    }
}

const regeneratePDF = (batchId) => async () => {
    try {
        const response = await request.post(`admin/batches/regenerate-pdf/${batchId}`);
        return response;
    } catch (error) {
        return error;
    }
}

export { fetchAdminBatches, fetchAdminBatchFilters, regeneratePDF }