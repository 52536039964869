import React, { useEffect, useState } from "react";

// Libraries/Packages
import { Elements } from "@stripe/react-stripe-js";

// Hooks
import { useSelector } from "react-redux";

// Actions
import { getClientSecret } from "~/redux/actions/stripe-actions";
import { loadStripe } from "@stripe/stripe-js";

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#0570de",
    colorBackground: "#ffffff",
    colorText: "#30313d",
    colorDanger: "#df1b41",
    fontFamily: "Inter, sans-serif",
    spacingUnit: "3px",
    borderRadius: "4px",
    iconHoverColor: "red",
  },
};

const Stripe = (props) => {
  const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_API_KEY);
  const [clientSecret, setClientSecret] = useState(null);

  const isAdmin =
    useSelector((state) => state.userReducers?.user?.isOrgAdmin) || false;

  useEffect(() => {
    if (isAdmin) {
      getClientSecret().then((res) => {
        setClientSecret(res?.data?.data?.intent?.client_secret);
      });
    }
  }, [isAdmin]);

  const options = {
    clientSecret,
    appearance,
    paymentMethodCreation: "manual",
  };

  return (
    <div className="flex container mt-8">
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          {props.children}
        </Elements>
      )}
    </div>
  );
};

export default Stripe;
