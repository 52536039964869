import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";
import { deleteCustomField } from "~/redux/actions/customFields-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { DeleteCDCIcon, CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteModal = (props) => {
  const { openModal, handleCloseModal, selectedCustomField, pagination} = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const deleteExistingCustomField = async () => {
    try {
      setLoading(true);
      const response = await dispatch(deleteCustomField(selectedCustomField.id, pagination));
      dispatch(success(response.data.message));
      handleCloseModal();
    } catch (error) {
      dispatch(failure(response.data.message));
    } finally {
      setLoading(false);
    }
  };


  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="customDeleteModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        className="crossIcon"
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={CrossModalCIcon} alt="crossIcon" />
      </IconButton>
      <DialogContent dividers className="deleteModalContent">
        <img src={DeleteCDCIcon} alt="delete" />
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.SETTINGS.CUSTOM_FIELDS?.DELETE?.DELETE_CUSTOM_FIELDS}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.SETTINGS.CUSTOM_FIELDS?.DELETE?.ARE_YOU_SURE}
          </Typography>
        </Box>
        <Box className="deleteModalBtns">
          <Button onClick={handleCloseModal}>{MESSAGES.SETTINGS.CUSTOM_FIELDS.DELETE.CANCEL_BUTTON}</Button>
          <Button onClick={deleteExistingCustomField}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.SETTINGS.CUSTOM_FIELDS.DELETE.SUBMIT_BUTTON
            )}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DeleteModal;
