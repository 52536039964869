import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Icons
import { NoContactCIcon } from "~/assets/images";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// styles
import "./styles.scss";

const NoOrdersScreen = () => {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noContactWrapper">
        <img src={NoContactCIcon} alt="no-contacts" />
        <Typography>{MESSAGES.ORDERS.NO_ORDERS_SCREEN.TITLE}</Typography>
        <Button onClick={() => navigate("/orders/create-order")}>
          <AddCircleOutlineIcon />
          {MESSAGES.ORDERS.NO_ORDERS_SCREEN.CREATE_ORDER_BUTTON}
        </Button>
      </Box>
    </Container>
  );
};

export default NoOrdersScreen;
