import React, { useState } from "react";

// Third Party Libraries
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import {
  addPaymentMethod,
  getAllPaymentMethods,
} from "~/redux/actions/stripe-actions.js";
import { getUserData } from "~/redux/actions/auth-actions.js";
import {
  failure,
  success,
} from "~/redux/actions/snackbar-actions.js";

// Utils
import { MESSAGES } from "~/utils/message.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { PaymentWalletCIcon, ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PaymentMethod = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleClose,
    open,
    handleExport,
    message,
    header,
    handleLiveModeSwitch,
  } = props;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!elements || !stripe) {
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError?.message) {
        return;
      }

      setLoading(true);
      const { paymentMethod, ...response } = await stripe.createPaymentMethod({
        elements,
      });
      if (paymentMethod) {
        addPaymentMethod(paymentMethod)
          .then(() => {
            dispatch(success(MESSAGES.ACCOUNT.WALLET.STRIPE.METHOD_ADDED, " "));
            dispatch(getUserData());
            dispatch(getAllPaymentMethods());
            if (typeof handleLiveModeSwitch == "function")
              handleLiveModeSwitch(true);
            handleClose();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            if (typeof handleLiveModeSwitch == "function")
              handleLiveModeSwitch(false);
            dispatch(
              failure(
                err?.response?.data?.message ||
                  MESSAGES.ACCOUNT.WALLET.STRIPE.METHOD_FAILED,
                " "
              )
            );
          });
      } else {
        dispatch(failure(response?.error?.message, " "));
      }
    } catch (error) {
      if (typeof handleLiveModeSwitch == "function")
        handleLiveModeSwitch(false);
      dispatch(failure(MESSAGES.ACCOUNT.WALLET.STRIPE.METHOD_FAILED, " "));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="paymentModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="paymentModalContent">
          <Box className="paymentHeader">
            <img src={PaymentWalletCIcon} alt="payment-icon" />
            <Typography gutterBottom variant="h4">
              {MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_PAYMENT_METHOD}
            </Typography>
          </Box>
          <Box className="stripePayment">
            <PaymentElement />
          </Box>
          <Box className="paymentModalBtns">
            <Button onClick={handleClose}>Cancel</Button>
            <Button disabled={loading} onClick={handleSubmit}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_PAYMENT_METHOD
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default PaymentMethod;
