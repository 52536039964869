//Actions
import { SET_GENERAL_SETTINGS, LOGOUT } from "~/redux/actions/action-types";

const initialState = {
  generalSettings: {
    propertyAddressStrictness: null,
    mergeVariableStrictness: null,
    addressStrictness: null,
  },
};

const generalSettingsReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GENERAL_SETTINGS:
      return {
        ...state,
        generalSettings: {
          propertyAddressStrictness: payload.propertyAddressStrictness,
          mergeVariableStrictness: payload.mergeVariableStrictness,
          addressStrictness: payload.addressStrictness,
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export { generalSettingsReducers };
