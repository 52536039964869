import request from '~/utils/request';

const getAllPlans = async () => {
  try {
    const response = await request.get('stripe/plans');
    return response;
  } catch (error) {
    return error.response;
  }
};


const fetchInvoices = (payload) => async (dispatch) => {
  try {
    const response = await request.get('/stripe/invoices/get', payload);
    dispatch({
      type: 'SET_STRIPE_INVOICES', payload: response.data.data,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const fetchActiveSubscription = async () => {
  try {
    const response = await request.get('stripe/subscriptions/active');
    return response;
  } catch (error) {
    return error.response;
  }
};



export { getAllPlans, fetchInvoices, fetchActiveSubscription };
