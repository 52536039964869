import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { cancelOrder  } from "~/redux/actions/orders-actions";

//Components
import ConfirmDialog from "~/components/Orders/ConfirmDialog/ConfirmDialog";

// Utils
import { dateFormat, doesItFallInCancellationPeriod, getTimeOnly } from "~/utils/date-format";
import { MESSAGES } from "~/utils/message";
import { createViewProofForOrder } from "~/utils/template-builder";
import {success, failure} from "~/redux/actions/snackbar-actions";

// MUI Components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Grid, CircularProgress } from "@mui/material";

// Icons
import { CancelOrderCIcon } from "~/assets/images";

// Styles
import "./styles.scss";


function CustomStepper({info, refreshOverview}) {
  const [activeStep, setActiveStep] = useState(0);
  const [proofLoading, setProofLoading] = useState(false);

  const [dialog, setDialog] = useState({ open: false, message: '', loading: false, currentAction: null, params: null });


  const {id} = useParams() 
  const dispatch = useDispatch();

  let steps = [
    {
      name: "Scheduled",
      date: `${dateFormat(info?.campaign?.deliveryDate, true)}`,
      time: getTimeOnly(info?.campaign?.deliveryDate)
    },
    {
      name: "Processing",
      date: dateFormat(info.processingDate, true),
      time: getTimeOnly(info.processingDate)
    },
    {
      name: "Mailed",
      date: dateFormat(info.mailedDate, true),
      time: getTimeOnly(info.mailedDate)
    },
    { name: "Expected Delivery Date", date: dateFormat(info.expectedDeliveryDate, true), },
    {
      name: "Completed",
      date: dateFormat(info.completedDate, true),
    },
  ];

  const handleCloseDialog = () => {
    setDialog({ open: false, message: '', loading: false, params: null, currentAction: null });
  }

  const handleOk = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    if(dialog.currentAction === 'cancel') {
      const payload = {orderId : id}
      const response = await dispatch(cancelOrder(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        refreshOverview();
      } else {
        dispatch(failure(response.data.message));
      }
    } 
    setDialog((prev) => ({ ...prev, loading: false, open: false }));

  }

  const handleCancelOrder = async ()=> {
    setDialog({ open: true, 
      message: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.MESSAGE, 
      loading: false, 
      currentAction: 'cancel', 
      successButtonName: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUCCESS_BUTTON,
      subMessage: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.SUB_MESSAGE,
      heading: MESSAGES.ORDERS.DETAIL.MODALS.CANCEL.HEADING,
      icon: CancelOrderCIcon
     })
  }


  const downloadViewProof = async () => {
    try {
      setProofLoading(true);

      await dispatch(
        createViewProofForOrder(`Order-${id}`, {
          orderId: id,
        })
      );
    } catch (error) {
      dispatch(failure(MESSAGES.VIEW_PROOF_ERROR));
    }
      setProofLoading(false)
  };


  useEffect(() => {
    switch (info.status?.toUpperCase()) {
      case "PROCESSING":
        setActiveStep(1);
        break;
      case "MAILED":
        setActiveStep(2);
        break;
      case "COMPLETED":
        setActiveStep(4)
        break;
      default:
        setActiveStep(0);
    }
  }, [info.status])

  return (
    <Box className="stepper_wrapper" sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item lg={9} md={12} sm={12} xs={12}>
          <div className="main_layer">
            <div className="custom-stepper">
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={`step ${index < activeStep ? "completed" : ""} ${index === activeStep ? "active" : ""
                    }`}
                >
                  <div className="stepOuter">
                    <div className="step-text-top">{step.name}</div>
                    <div className="bullet"></div>
                    <div className="bottomtxt">{step.date}</div>
                    <div className="bottomtxt">{step.time}</div>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`${index < activeStep ? "completed" : ""}`}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </Grid>

        <Grid item lg={3} md={12} sm={12} xs={12}>
          <div className="btn_outer">
          {(info.status == "Scheduled" && doesItFallInCancellationPeriod(info?.campaign?.deliveryDate)) &&  <Button onClick={() => handleCancelOrder()}>{MESSAGES.ORDERS.DETAIL.OVERVIEW.CANCEL_ORDER_BUTTON}</Button> }
          {proofLoading ? 
          <Button disabled style={{width: 97}}>
          <CircularProgress
                sx={{
                  color: "#ED5C2F",
                  width: "20px !important",
                  height: "20px !important",
                }}
              /></Button> :  <Button onClick={() => downloadViewProof()}>{MESSAGES.ORDERS.DETAIL.OVERVIEW.VIEW_PROOF_BUTTON}</Button>}
          </div>
        </Grid>
      </Grid>
      {
        dialog.open && <ConfirmDialog dialog={dialog} open={dialog.open} loading={dialog.loading} handleClose={handleCloseDialog} handleOk={handleOk} />
      }
    </Box>
  );
}
export default CustomStepper;
