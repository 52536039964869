// Request util for api call
import request from "~/utils/request";
// Utils
import { setApiKeys, setDashboardMode, setResetCodeAndToken, setResetTokenAndEmail } from "~/utils/auth";
import { clearStorage } from "~/utils/local-storage";

// Actions Types
import { SET_USER_DATA } from "./action-types"

const getHubspotContactProperties = async () => {
  try {
    const response = await request.get("auth/hubspot/properties");

    if(response?.data?.data?.properties) {
      return response.data.data.properties;
    }

    return [];
  } catch (error) {
    return [];
  }
}

const createUser = (payload, joinTeam) => async (dispatch) => {
  try {
    const user = await request.post(joinTeam ? "auth/join-team" : "auth/signup", payload);
    setApiKeys(user.data.data.token, user.data.data.refreshToken)
    if (user?.data?.data?.user?.isSystemAdmin) {
      setDashboardMode(true);
    }
    dispatch({ type: SET_USER_DATA, payload: user.data.data.user });
    user.message = 'success';
    return user;
  } catch (error) {
    return error;
  }
}

const signInUser = (payload) => async (dispatch) => {
  try {
    const user = await request.post("auth/login", payload);
    if (user) {
      setApiKeys(user.data.data.token, user.data.data.refreshToken)
      if (user?.data?.data?.user?.isSystemAdmin) {
        setDashboardMode(true);
      }
      dispatch({ type: SET_USER_DATA, payload: user.data.data.user });
      user.message = 'success';
    }
    return user;
  } catch (error) {
    return error;
  }
};

const getUserData = () => async (dispatch) => {
  try {
    const userData = await request.post("auth/me");
    dispatch({ type: SET_USER_DATA, payload: userData.data.data });
    return userData;
  } catch (error) {
    return error
  }
}

const ForgotUserPassword = (payload) => async () => {
  try {
    const resetPassword = await request.post("auth/forgot-password", payload);
    setResetTokenAndEmail(resetPassword.data.data.token, payload.email);
    resetPassword.message = 'success';
    return resetPassword;
  } catch (error) {
    return error;
  }
};

const verifyUserToken = (payload) => async () => {
  try {
    const verify = await request.post("auth/verify-reset-code", payload);
    setResetCodeAndToken(payload.code, verify.data.data.token);
    verify.message = 'success';
    return verify;
  } catch (error) {
    return error;
  }
};

const updateUserPassworrd = (payload) => async () => {
  try {
    const updatePassword = await request.post("auth/reset-password", payload);
    clearStorage();
    updatePassword.message = 'success';
    return updatePassword;
  } catch (error) {
    return error;
  }
}

export { getHubspotContactProperties, createUser, signInUser, ForgotUserPassword, verifyUserToken, updateUserPassworrd, getUserData };
