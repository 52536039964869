import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { isAdmin } from "~/hooks/permissions";

// Actions
import {
  fetchGeneralSetting,
  updateGeneralSetting,
} from "../../../redux/actions/general-actions";
import { failure, success } from "../../../redux/actions/snackbar-actions";

//Utils
import { MESSAGES } from "~/utils/message";
import { GENERAL_SETTINGS_LEARN_URL } from "~/utils/constants";

// MUI Components
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";

// Icons
import { EditCDCIcon } from "~/assets/images";
import DoneIcon from "@mui/icons-material/Done";

// Styles
import "./styles.scss";

const General = () => {
  const [addressStrictness, setAddressStrictness] = useState(null);
  const [variableStrictness, setVariableStrictness] = useState(null);
  const [propertyAddressStrictness, setPropertyAddressStrictness] =
    useState(null);
  const [returnAddressName, setReturnAddressName] = useState(null);
  const [returnAddressLastName, setReturnAddressLastName] = useState(null);
  const [returnAddressCompany, setReturnAddressCompany] = useState(null);
  const [returnAddressAddress, setReturnAddressAddress] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getGeneralSetting = async () => {
    setLoading(true);
    const settings = await dispatch(fetchGeneralSetting());
    if (settings.status === 200) {
      setAddressStrictness(settings.data.data.addressStrictness);
      setVariableStrictness(settings.data.data.mergeVariableStrictness);
      setPropertyAddressStrictness(
        settings.data.data.propertyAddressStrictness
      );
      setReturnAddressName(settings.data.data.returnAddressSettings.firstName);
      setReturnAddressLastName(settings.data.data.returnAddressSettings.lastName);
      setReturnAddressCompany(settings.data.data.returnAddressSettings.companyName);
      setReturnAddressAddress(settings.data.data.returnAddressSettings.fullAddress);
    }
    setLoading(false);
  };

  const updateSetting = async () => {
    let payload = {
      addressStrictness: addressStrictness,
      mergeVariableStrictness: variableStrictness,
      propertyAddressStrictness: propertyAddressStrictness,
      returnAddressSettings: {
        firstName: returnAddressName,
        lastName: returnAddressLastName,
        companyName: returnAddressCompany,
        fullAddress: returnAddressAddress,
      },
    };
    setLoading(true);
    const update = await dispatch(updateGeneralSetting(payload));
    if (update.status === 200) {
      setEditMode(false);
      dispatch(success(update.data.message));
      await dispatch(fetchGeneralSetting());
    }
    else if(update.status !== 200) {
      dispatch(failure(MESSAGES.SETTINGS.GENERAL_SETTINGS.RA_ERROR_MESSAGE));
    }
    setLoading(false);
  };

  useEffect(() => {
    getGeneralSetting();
  }, [editMode]);

  return (
    <>
      <Box className="generalSettingsWrapper">
        <Box className="settingSection">
          <Grid container>
            <Grid item xl={7} lg={7} md={7} sm={4} xs={6}>
              <Grid
                item
                lg={8}
                md={8}
                sm={12}
                xs={12}
                className="searchBtnWrapper"
              >
                <Box className="generalWrapper">
                  <Typography>
                    {MESSAGES.SETTINGS.GENERAL_SETTINGS.TITLE}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xl={5} lg={5} md={5} sm={8} xs={6}>
              <Box className="editBtnWrapper">
                {isAdmin() && (
                  <Button className="editBtn" onClick={() => setEditMode(true)}>
                    <img src={EditCDCIcon} alt="trash" />
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.EDIT_BTN_TEXT}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ marginTop: "30px" }}>
            <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
              <Box className="addressStrictWrapper">
                <Typography>
                  {MESSAGES.SETTINGS.GENERAL_SETTINGS.PROPERTY_STRICT_LABEL}
                </Typography>
                <NavLink to={GENERAL_SETTINGS_LEARN_URL} target="_blank">
                  <div className="link">
                    {MESSAGES.SETTINGS.GENERAL_SETTINGS.LEARN_TITLE}
                  </div>
                </NavLink>
              </Box>
            </Grid>
            <Grid item xl={3} lg={4} md={3} sm={12} xs={12}>
              <Box className={!editMode ? "disabled" : ""}>
                <FormGroup className="generalCheckWrapper">
                  <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              propertyAddressStrictness === "Include"
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() =>
                              setPropertyAddressStrictness("Include")
                            }
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={propertyAddressStrictness === "Include"}
                          />
                        }
                        label={
                          MESSAGES.SETTINGS.GENERAL_SETTINGS
                            .PROPERTY_INCLUDE_LABEL
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              propertyAddressStrictness === "Exclude"
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() =>
                              setPropertyAddressStrictness("Exclude")
                            }
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={propertyAddressStrictness === "Exclude"}
                          />
                        }
                        label={
                          MESSAGES.SETTINGS.GENERAL_SETTINGS
                            .PROPERTY_EXCLUDE_LABEL
                        }
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider className="generalDivider" />
        <Box className="settingSection">
          <Grid container sx={{ marginTop: "30px" }}>
            <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
              <Box className="addressStrictWrapper">
                <Typography>
                  {MESSAGES.SETTINGS.GENERAL_SETTINGS.ADDRESS_STRICT_LABEL}
                </Typography>
                <NavLink to={GENERAL_SETTINGS_LEARN_URL} target="_blank">
                  <div className="link">
                    {MESSAGES.SETTINGS.GENERAL_SETTINGS.LEARN_TITLE}
                  </div>
                </NavLink>
              </Box>
            </Grid>
            <Grid item xl={3} lg={4} md={3} sm={12} xs={12}>
              <Box className={!editMode ? "disabled" : ""}>
                <FormGroup className="generalCheckWrapper">
                  <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              addressStrictness === "Strict"
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() => setAddressStrictness("Strict")}
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={addressStrictness === "Strict"}
                          />
                        }
                        label={MESSAGES.SETTINGS.GENERAL_SETTINGS.STRICT_LABEL}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              addressStrictness === "Normal"
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() => setAddressStrictness("Normal")}
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={addressStrictness === "Normal"}
                          />
                        }
                        label={MESSAGES.SETTINGS.GENERAL_SETTINGS.NORMAL_LABEL}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider className="generalDivider" />
        {/* new checkboxes for return address Name, Last Name, Company Name, Address */}
        <Box className="settingSection">
          <Grid container sx={{ marginTop: "30px" }}>
            <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
              <Box className="addressStrictWrapper">
                <Typography>
                  {MESSAGES.SETTINGS.GENERAL_SETTINGS.RETURN_ADDRESS_NAME_LABEL}
                </Typography>
                <NavLink to={GENERAL_SETTINGS_LEARN_URL} target="_blank">
                  <div className="link">
                    {MESSAGES.SETTINGS.GENERAL_SETTINGS.LEARN_TITLE}
                  </div>
                </NavLink>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box className={!editMode ? "disabled" : ""}>
                <FormGroup className="generalCheckWrapper">
                  <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              returnAddressName === true
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() => setReturnAddressName(!returnAddressName)}
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={returnAddressName === true}
                          />
                        }
                        label={MESSAGES.SETTINGS.GENERAL_SETTINGS.RA_NAME_LABEL}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              returnAddressLastName === true
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() => setReturnAddressLastName(!returnAddressLastName)}
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={returnAddressLastName === true}
                          />
                        }
                        label={MESSAGES.SETTINGS.GENERAL_SETTINGS.RA_LAST_NAME_LABEL}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              returnAddressCompany === true
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() => setReturnAddressCompany(!returnAddressCompany)}
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={returnAddressCompany === true}
                          />
                        }
                        label={MESSAGES.SETTINGS.GENERAL_SETTINGS.RA_COMPANY_LABEL}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <FormControlLabel
                        className="checkBoxLabel"
                        control={
                          <Checkbox
                            className={
                              returnAddressAddress === true
                                ? `customCheckbox checked`
                                : `customCheckbox`
                            }
                            disabled={!editMode}
                            onClick={() => setReturnAddressAddress(!returnAddressAddress)}
                            icon={<Box className="notChecked"></Box>}
                            checkedIcon={<DoneIcon />}
                            checked={returnAddressAddress === true}
                          />
                        }
                        label={MESSAGES.SETTINGS.GENERAL_SETTINGS.RA_ADDRESS_LABEL}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {isAdmin() && (
          <Box className="settingSection">
            <Box className="rateWrapper">
              <Typography>
                {MESSAGES.SETTINGS.GENERAL_SETTINGS.RATE_LABEL}
              </Typography>
            </Box>
            <Grid container>
              <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                <Box className={!editMode ? "disabled" : ""}>
                  <Box className="productsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.PRODUCT_LABEL}
                    </Typography>
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.PRODUCT_VALUE_1}
                    </Typography>
                  </Box>
                  <Box className="productsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.PRODUCT_LABEL}
                    </Typography>
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.PRODUCT_VALUE_2}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={3} lg={4} md={12} sm={12} xs={12}>
                <Box
                  className={
                    !editMode ? "disabled rateLimitWrapper" : "rateLimitWrapper"
                  }
                >
                  <Box className="productsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.RATE_LIMIT_LABEL}
                    </Typography>
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.RATE_VALUE_1}
                    </Typography>
                  </Box>
                  <Box className="productsWrapper">
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.RATE_LIMIT_LABEL}
                    </Typography>
                    <Typography>
                      {MESSAGES.SETTINGS.GENERAL_SETTINGS.RATE_VALUE_2}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        {editMode && (
          <Box className="cancebtnWrapper">
            <Button onClick={() => setEditMode(false)}>
              <Typography>
                {MESSAGES.SETTINGS.GENERAL_SETTINGS.CANCEL_BUTTON}
              </Typography>
            </Button>
            <Button onClick={updateSetting}>
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                <Typography>
                  {MESSAGES.SETTINGS.GENERAL_SETTINGS.SUBMIT_BUTTON}
                </Typography>
              )}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
export default General;
