import {
  GET_ALL_TRANSACTIONS,
  SET_STRIPE_PAYMENT_METHODS,
  SET_STRIPE_INVOICES,
  SET_TRANSACTIONS_LOADER,
  SET_METHOD_LOADER,
  LOGOUT
} from '~/redux/actions/action-types';

const initialState = {
  methods: [],
  areMethodsLoading: true,
  transactions: {
    count: 0,
    rows: [],
    total: 0,
    perPage: 10,
    currentPage: 1,
    lastPage: 1,
    from: 1,
    to: 10,
    totalRecordsInDB: 0,
    loading: false,
  },
  invoices: {
    rows: [],
    endingBefore: null,
    startingAfter: null,
    has_more: false,
  }
};

const stripeReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STRIPE_PAYMENT_METHODS:
      return { ...state, methods: payload.data };
    case SET_STRIPE_INVOICES:
      return {
        ...state, invoices: {
          ...payload,
        },
      };
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        transactions: {
          ...payload.data,
        },
      };
    case SET_TRANSACTIONS_LOADER:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: payload
        },
      };
    case SET_METHOD_LOADER:
      return {
        ...state,
        areMethodsLoading: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export { stripeReducers };
