// Request util for api call
import request from "~/utils/request";

// Actions Types
import { SET_TEAM_MEMBERS_DATA, SET_USER_LOADER } from "./action-types";

const getTeamMembersData = (payload) => async (dispatch) => {
    try {
        const teamMembers = await request.get("users", payload);
        dispatch({ type: SET_USER_LOADER, payload: true });
        dispatch({ type: SET_TEAM_MEMBERS_DATA, payload: teamMembers.data.data });
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_USER_LOADER, payload: false });
    }
}

const inviteMember = (payload) => async () => {
    try {
        const invite = await request.post("users/invite", payload);
        return invite;
    } catch (error) {
        return error.response;
    }
}

const resendInviteEmail = (payload) => async () => {
    try {
        const resend = await request.get(`users/resend-invite/${payload}`);
        return resend;
    } catch (error) {
        return error.response;
    }
}

const deleteMember = (payload) => async () => {
    try {
        const deleted = await request.delete(`users/${payload}`);
        return deleted;
    } catch (error) {
        return error.response;
    }
}

const updateRole = (payload) => async () => {
    try {
        const role = await request.post("users/update-role", payload);
        return role;
    } catch (error) {
        return error.response;
    }
}

export { getTeamMembersData, inviteMember, updateRole, deleteMember, resendInviteEmail };
