// Request util for api call
import request from "~/utils/request";

// Actions
import { SET_CUSTOM_FIELDS_DATA, SET_CUSTOM_FIELDS_LOADER } from "./action-types";

const fetchCustomFields = (payload ) => async (dispatch) => {
    try {
        dispatch({ type: SET_CUSTOM_FIELDS_LOADER, payload: true });
        const customFields = await request.get('custom-fields/get', payload);
        dispatch({ type: SET_CUSTOM_FIELDS_DATA, payload: customFields.data.data });
        return customFields;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_CUSTOM_FIELDS_LOADER, payload: false });
    }
}

const createCustomField = (payload, pagination) => async (dispatch) => {
    try {
        const customField = await request.post('custom-fields', payload);
        dispatch(fetchCustomFields(pagination));
        return customField;
    } catch (error) {
        return error.response;
    }
}

const updateCustomField = (id, payload, pagination) => async (dispatch) => {
    try {
        const updatedCustomField = await request.put(`custom-fields/${id}`, payload);
        dispatch(fetchCustomFields(pagination));
        return updatedCustomField;
    } catch (error) {
        return error.response;
    }
}

const deleteCustomField = (id, pagination) => async (dispatch) => {
    try {
        const deletedCustomField = await request.delete(`custom-fields/${id}`);
        dispatch(fetchCustomFields(pagination));
        return deletedCustomField;
    } catch (error) {
        return error.response;
    }
}

export { fetchCustomFields, createCustomField, deleteCustomField, updateCustomField };
