// Actions
import {
  SET_SOURCES,
  SET_SOURCES_LOADER,
  LOGOUT,
} from "~/redux/actions/action-types";

const initialState = {
  sources: [],
  loading: false,
};

const sourcesReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SOURCES:
      return {
        ...state,
        sources: payload.data,
      };
    case SET_SOURCES_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export { sourcesReducers };
