import request from "~/utils/request";

import { SET_USER_DATA } from "./action-types"

const updateMode = (payload) => async (dispatch) => {
    try {
        const mode = await request.post("auth/user/mode-switch", payload);
        dispatch({ type: SET_USER_DATA, payload: mode.data.data.user });
        return mode;
    } catch (error) {
        return error;
    }
}

export { updateMode }