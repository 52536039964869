import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { upgradePlan } from "~/redux/actions/stripe-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";
import { getUserData } from "~/redux/actions/auth-actions";

// Utils
import { balanceFormatter } from "~/utils/helperFunctions";
import { MESSAGES } from "~/utils/message";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Image
import { ModalClosePlansCIcon, UpgradeCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UpgradeModal = (props) => {
  const { handleClose, open, selectedPlan, planDetails } = props;
  const [requestLoader, setRequestLoader] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setRequestLoader(true);
      const response = await upgradePlan({ planId: selectedPlan.stripeId });
      if (response.status === 200) {
        dispatch(getUserData());
        dispatch(success(response?.data?.message));
        handleClose();
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
    } finally {
      setRequestLoader(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="upgradeModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <img src={ModalClosePlansCIcon} alt="close" />
        </IconButton>
        <DialogContent className="upgradeModalContent" dividers>
          <>
            <img src={UpgradeCIcon} alt="upgrade" />
            <Box className="upgradeHeader">
              <Typography gutterBottom variant="h4">
                {MESSAGES.ACCOUNT.PLANS.UPGRADE.TITLE}
              </Typography>
              <Box className="package">
                <Typography gutterBottom>{selectedPlan.name}</Typography>
                {selectedPlan.amount && (
                  <Typography gutterBottom>
                    {balanceFormatter(selectedPlan.amount)}/
                    {selectedPlan.isYearly ? "yr" : "month"}
                  </Typography>
                )}
              </Box>
              <Box className="description">
                <Typography gutterBottom>
                  You will be charged {balanceFormatter(planDetails?.cost)}{" "}
                  today. Your plan will upgrade immediately. Starting on{" "}
                  {planDetails?.nextChargeDate || ""} you will be billed for{" "}
                  {balanceFormatter(selectedPlan.amount)}
                  {selectedPlan.isYearly ? " every year." : " every month."}
                </Typography>
              </Box>
            </Box>
            <Box className="upgradeModalBtns">
              <Button onClick={handleClose}>
                {MESSAGES.ACCOUNT.PLANS.UPGRADE.CANCEL_BUTTON}
              </Button>
              <Button onClick={onSubmit}>
                {requestLoader ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.PLANS.UPGRADE.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default UpgradeModal;
