import React, { useEffect, useRef } from "react";

// Hooks
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isSystemAdmin } from "~/hooks/permissions";

// Actions
import { LOGOUT } from "~/redux/actions/action-types";

// Utils
import {
  HEADERS_DROPDOWN,
  ADMIN_HEADERS_DROPDOWN,
  HELP_CENTER_URL,
} from "~/utils/constants";

// MUI Components
import { Box, Typography } from "@mui/material";

// Icons
import { HelpCenterCIcon, ChatCIcon } from "~/assets/images";

const HelpDropdown = (props) => {
  const dropDownRef = useRef(null);
  const { setHelpDropDown, dropValue, isAdminMode, onClick, isWidgetVisible, setIsWidgetVisible } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let currentDropdown = isAdminMode ? ADMIN_HEADERS_DROPDOWN : HEADERS_DROPDOWN;

  currentDropdown = currentDropdown.filter(
    (list) => isSystemAdmin() || list.name !== "Admin Portal"
  );

  const handleAfterClick = (fn, name) => {
    if (name === "Logout") {
      dispatch({ type: LOGOUT });
    }
    fn((path) => navigate(path));
    setHelpDropDown(false);
  };

  const handleClickAway = () => {
    setOpen(false); // Close the dropdown when clicking away
  };

  useEffect(() => {
    let counter = 0;
    const handleClickOutside = (event) => {
      if (
        counter == 1 &&
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target)
      ) {
        setHelpDropDown(false);
      } else {
        counter = 1;
      }
    };

    if (dropValue == true) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropValue]);

  // Close dropdown when the chatbot is open
  useEffect(() => {
    if (isWidgetVisible) {
      setHelpDropDown(false);
    }
    setIsWidgetVisible(false);
  }, [isWidgetVisible]);

  return (
    <>
      <Box ref={dropDownRef} className="dropDownWrapper helpDropdown">
        <NavLink to={HELP_CENTER_URL} target="_blank">
          <Box className="dropDownItem">
            <div className="imgOuter">
              <img src={HelpCenterCIcon} alt="icon" />
            </div>
            <Typography>Help Center</Typography>
          </Box>
        </NavLink>
        <Box className="dropDownItem" onClick={onClick}>
          <div className="imgOuter">
            <img src={ChatCIcon} alt="chat" />
          </div>
          <Typography>Chat with Us</Typography>
        </Box>
      </Box>
    </>
  );
};

export default HelpDropdown;
