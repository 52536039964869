import React, { useState } from "react";

//Hooks
import { useDispatch } from "react-redux";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions";

//Utils
import { MESSAGES } from "~/utils/message";
import { setItem } from "~/utils/local-storage.js";
import request from "~/utils/request";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, FormControl, Grid, CircularProgress } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";

// Icons
import { ModalCrossCIcon, GreenTickCIcon, RedCrossCIcon } from "~/assets/images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ContactsMapping = (props) => {
  const {
    handleClose,
    open,
    mappingFileds,
    options,
    updateMapping,
    selectedFile,
    setRequestId,
    setProcessCsv,
  } = props;
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (params, event) => {
    const updatedData = mappingFileds.map((item) => {
      if (item.label === params) {
        return { ...item, value: event.target.value, error: false };
      }
      return item;
    });
    updateMapping(updatedData);
  };

  const handleClearSelection = (params) => {
    const clearSelction = mappingFileds.map((item) => {
      if (item.label === params) {
        return { ...item, value: "" };
      }
      return item;
    });
    updateMapping(clearSelction);
  };

  const validateFields = () => {
    let inValids = [];
    const validatedData = mappingFileds.map((item) => {
      if (item.required && !item.value) {
        inValids.push(item.label);
        return { ...item, error: true };
      }
      return item;
    });
    updateMapping(validatedData);
    if (inValids.includes("First Name") && !inValids.includes("Company")) {
      inValids = inValids.filter((item) => item !== "First Name");
    }
    if (inValids.includes("Company") && !inValids.includes("First Name")) {
      inValids = inValids.filter((item) => item !== "Company");
    }
    if (inValids.includes("Company") && inValids.includes("First Name")) {
      inValids = inValids.filter(
        (item) => item !== "Company" && item !== "First Name"
      );
      inValids.unshift("First Name or Company");
    }
    if (inValids.length) {
      setErrors(inValids);
      return false;
    } else {
      setErrors([]);
      return true;
    }
  };

  const handleExport = async () => {
    try {
      const isValid = validateFields();
      if (isValid) {
        let headers = mappingFileds.map((item) => {
          return {
            dbColumn: item.dbColumn,
            csvColumn: item.value,
          };
        });
        setLoading(true);
        const form = new FormData();
        form.append("headers", JSON.stringify(headers));
        form.append("csv", selectedFile[0]);
        const response = await request.post("contacts/processCsv", form);
        setRequestId(response.data.data.reqId);
        setItem("reqId", response.data.data.reqId);
        setProcessCsv((prev) => ({
          ...prev,
          current: response.data.data.current,
          processing: true,
          total: response.data.data.total,
        }));
        handleClose();
        dispatch(success(response.data.message));
      }
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="importModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="importModalContent" dividers>
          <Box className="importModalHeader">
            <Typography variant="h4">
              {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.TITLE}
            </Typography>
          </Box>
          <Box className="mainContent">
            <Box className="importModalDetails">
              <Grid container className="detailsHeader">
                <Grid item lg={1} md={1} sm={1} xs={1}>
                  <Typography>
                    {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.MATCH_LABEL}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography>
                    {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.OLC_LABEL}
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Typography>
                    {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.CSV_LABEL}
                  </Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography>
                    {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.SAMPLE_LABEL}
                  </Typography>
                </Grid>
              </Grid>
              <Box className="detailsRow">
                {mappingFileds.map((content, index) => {
                  return (
                    <Grid
                      container
                      className={`dataRow ${
                        content.value
                          ? `verfied`
                          : content.error &&
                            errors.some((str) => str.includes(content.label))
                          ? `mapingError`
                          : `notVerfied`
                      }`}
                      key={index}
                    >
                      <Grid item lg={1} md={1} sm={1} xs={1}>
                        <img src={content.value ? GreenTickCIcon : RedCrossCIcon} alt="green" />
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography>
                          {content.label}
                          {content.required
                            ? MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.REQUIRED
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <FormControl>
                          <Select
                            id="demo-multiple-checkbox"
                            displayEmpty
                            value={content.value}
                            onChange={(e) => handleChange(content.label, e)}
                            inputProps={{
                              IconComponent: () => (
                                <Box className="selectActions">
                                  {content.value && (
                                    <CloseIcon
                                      onClick={() => {
                                        handleClearSelection(content.label);
                                      }}
                                    />
                                  )}
                                  <KeyboardArrowDownIcon />
                                </Box>
                              ),
                            }}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <Typography
                                    sx={{ opacity: "0.5", fontSize: "14px" }}
                                  >
                                    {content.label}
                                  </Typography>
                                );
                              }
                              return selected;
                            }}
                            MenuProps={MenuProps}
                            className="statuSelect"
                          >
                            {options.map(({ name }, id) => (
                              <MenuItem key={id} value={name}>
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Box className="sampleDataWrapper">
                          {options.map((item, id) =>
                            item.name.trim() === content.value ? (
                              <Typography key={id + "x"}>
                                {item.examples[0]}
                                <br />
                                {item.examples[1]}
                              </Typography>
                            ) : null
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
                <Box className="contactsErrorWrapper">
                  {errors &&
                    errors.map((content, id) => {
                      return (
                        <Typography key={id + "errors"} className="errorFields">
                          {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.REQUIRED}
                          {content}
                          {
                            MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING
                              .REQUIRED_TEXT
                          }
                        </Typography>
                      );
                    })}
                </Box>
              </Box>
            </Box>

            <Box className="importModalBtns">
              <Button onClick={handleClose}>
                {MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.CANCEL_BUTTON}
              </Button>
              <Button onClick={handleExport}>
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.CONTACTS.IMPORT.CONTACTS_MAPPING.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ContactsMapping;
