import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  associateTemplateCategory,
  fetchCategoryDetails,
} from "~/redux/actions/admin-templates-actions";
import { getAllTemplates } from "~/redux/actions/template-builder";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE } from "~/utils/constants";

// Components
import GenericAutocomplete from "~/components/General/Autocomplete";
import Loader from "~/components/General/Loader";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AssociateTemplatesModal = (props) => {
  const { openModal, handleCloseModal, selectedTemplateCategory, pagination } =
    props;
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [detailLoader, setDetailLoader] = useState(false);
  const [errors, setErrors] = useState({
    template: false,
  });

  const dispatch = useDispatch();

  const templates = useSelector(
    (state) => state.templateReducer.templates.rows
  );

  const templateOptions =
    templates.map((template) => {
      return {
        id: template.id,
        label: `ID:${template.id} ${template.title}`,
      };
    }) || [];

  const onClose = () => {
    handleCloseModal();
    setSelectedTemplates([]);
  };

  const handleAssociateTempsWithCategory = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      const payload = {
        categoryId: selectedTemplateCategory?.id,
        templateIds: selectedTemplates.map(({ id }) => id),
      };
      const response = await dispatch(
        associateTemplateCategory(payload, pagination)
      );
      dispatch(success(response?.data?.message));
      onClose();
    } catch (error) {
      dispatch(failure(error?.response?.data?.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  const searching = async () => {
    setSearchLoading(true);
    await dispatch(
      getAllTemplates(
        DEFAULT_PAGINATION_WITHOUT_PAGE_SIZE.page,
        100,
        search?.includes("ID:") ? search?.replace(/ID:\d+\s*/, "") : search
      )
    );
    setSearchLoading(false);
  };

  const getSelectedCategoryDetail = async () => {
    try {
      setDetailLoader(true);
      const categoryDetail = await dispatch(
        fetchCategoryDetails(selectedTemplateCategory?.id, pagination)
      );
      if (categoryDetail.status === 200) {
        const existingTemplates = categoryDetail.data.data.templates.map(
          (template) => {
            return {
              id: template.id,
              label: `ID:${template.id} ${template.title}`,
            };
          }
        );
        setSelectedTemplates(existingTemplates);
        setDetailLoader(false);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    searching();
  }, [search]);

  useEffect(() => {
    getSelectedCategoryDetail();
  }, [openModal]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="assignTempToCategoryWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="assignTempToCategoryContent" dividers>
          {detailLoader ? (
            <Box>
              <Loader />
            </Box>
          ) : (
            <Box>
              <Box className="assignTempToCategoryHeader">
                <Typography gutterBottom variant="h4">
                  {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.ASSIGN_TEMPLATE.TITLE}
                </Typography>
              </Box>
              <form
                className="updateFormWrapper"
                onSubmit={handleAssociateTempsWithCategory}
              >
                <Box className="assignTempToCategoryFormFields">
                  <InputLabel htmlFor="Name" className="inputLabel">
                    {
                      MESSAGES.ADMIN_DASHBOARD.CATEGORIES.ASSIGN_TEMPLATE
                        .TEMPLATES_LABEL
                    }
                  </InputLabel>
                  <GenericAutocomplete
                    placeholder={
                      MESSAGES.ADMIN_DASHBOARD.CATEGORIES.ASSIGN_TEMPLATE
                        .TEMPLATES_PLACEHOLDER
                    }
                    options={templateOptions}
                    setSelectedValue={setSelectedTemplates}
                    selectedValue={selectedTemplates}
                    search={setSearch}
                    updateErrors={setErrors}
                    name={"template"}
                    loading={searchLoading}
                    multiple={true}
                  />
                </Box>
                <Box className="assignTempToCategoryBtns">
                  <Button className="btnCancel" onClick={onClose}>
                    {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.CANCEL_BUTTON}
                  </Button>
                  <Button className="btnSave" type="submit">
                    {loading ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          width: "25px !important",
                          height: "25px !important",
                        }}
                      />
                    ) : (
                      MESSAGES.ADMIN_DASHBOARD.CATEGORIES.ASSIGN_TEMPLATE
                        .SUBMIT_BUTTON
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default AssociateTemplatesModal;
