// Request util for api call
import request from "~/utils/request";

// Actions Types
import { SET_ADMIN_ORDERS_DATA, SET_ADMIN_ORDERS_FILTERS, SET_ADMIN_ORDERS_FILTERS_LABELS, SET_ADMIN_ORDERS_LOADER, CLEAR_ADMIN_ORDERS_FILTERS, LOGOUT, SET_ADMIN_ORDER_DISPATCH_LOADER } from "./action-types";

// Utiils
import { dateFormat } from "~/utils/date-format";
import { DEFAULT_PAGINATION } from "~/utils/constants";
import { balanceFormatter } from "~/utils/helperFunctions";

const fetchAdminOrders = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_ADMIN_ORDERS_LOADER, payload: true });

        let url = 'admin/orders';

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'createdAt' || key === 'scheduledDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            let date = Object?.entries(value)
                                .map(([nestedKey, nestedValue]) => `${JSON.stringify(nestedKey)}:${JSON.stringify(nestedValue)}`)
                                .join(', ');
                            queryPayload[key] = `{${date}}`;
                        }
                    }
                    else if (value.length) {
                        queryPayload[key] = `${JSON.stringify(value)}`;
                    }
                })
        }

        if (payload?.batchId) {
            queryPayload.batchId = payload.batchId
        }

        const response = await request.get(url, queryPayload);


        if (response.status === 200) {
            const combinedData = response.data.data.rows.map((order, i) => ({
                key: i,
                orderId: order.id,
                userId: order.createdBy,
                createdBy: order?.creator?.fullName || ' -',
                batchId: order.batchId,
                source: order.source,
                productType: order.product['productType'],
                quantity: order.quantity,
                deliverableQuantity: order.deliverableQuantity,
                revenue: order.cost ? balanceFormatter(order.cost) : ' -',
                scheduleDate: dateFormat(order?.campaign?.deliveryDate, true),
                mailingDate: dateFormat(order?.mailedDate, true),
                status: order.status,
                paymentStatus: order.paymentStatus,
                dispatchedAt: order.dispatchedAt,
            }));

            const orders = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
                totalRevenue: response.data.data.totalRevenue,
                totalOrders: response.data.data.total,
            }

            dispatch({ type: SET_ADMIN_ORDERS_DATA, payload: orders });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ADMIN_ORDERS_LOADER, payload: false });
    }
}

const fetchAdminOrdersFilters = () => async (dispatch) => {
    try {
        const filters = await request.get("admin/orders/filters-data");
        let modifiedLabels = {}
        Object.keys(filters.data.data).forEach((key) => {
            if (typeof filters.data.data[key] === 'object') {
                const combinedData = Object.entries(filters.data.data[key]).map(([valueKey, item], id) => ({
                    id,
                    title: item,
                    value: valueKey || item,
                }));
                modifiedLabels[key] = combinedData;
            }

        });
        dispatch({ type: SET_ADMIN_ORDERS_FILTERS_LABELS, payload: modifiedLabels });
    } catch (error) {
        return error;
    }
}

const cancelOrderByAdmin = (payload) => async () => {
    try {
        const cancelOrder = await request.delete(`admin/orders/${payload}`);
        return cancelOrder;
    } catch (error) {
        return error.response;
    }
}

const dispatchOrderNowByAdmin = (orderId) => async (dispatch) => {
    try {
        dispatch({ type: SET_ADMIN_ORDER_DISPATCH_LOADER, payload: orderId });

        const response = await request.post('admin/orders/dispatch-now', {
            orderIds: [orderId]
        });

        return response;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_ADMIN_ORDER_DISPATCH_LOADER, payload: null });
    }
};

export { fetchAdminOrders, fetchAdminOrdersFilters, cancelOrderByAdmin, dispatchOrderNowByAdmin }
