import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { deleteWebhook } from "~/redux/actions/webhooks-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { ModalCrossCIcon, DeleteCDCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteWebhookModal = (props) => {
  const { openModal, handleCloseModal, selectedWebhook } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await dispatch(deleteWebhook(selectedWebhook));
      dispatch(success(response.data.message));
      handleCloseModal();
      navigate("/settings/webhooks");
    } catch (error) {
      dispatch(failure(response.data.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="webhookModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent dividers className="webhookModalContent">
        <img src={DeleteCDCIcon} alt="delete" />
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.SETTINGS.WEBHOOKS.DELETE.TITLE}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.SETTINGS.WEBHOOKS.DELETE.HEADING}
          </Typography>
          <Typography className="footerText">
            {MESSAGES.SETTINGS.WEBHOOKS.DELETE.DESCRIPTION}
          </Typography>
        </Box>
        <Box className="webhookModalBtns">
          <Button onClick={handleCloseModal}>
            {MESSAGES.SETTINGS.WEBHOOKS.DELETE.CANCEL_BUTTON}
          </Button>
          <Button onClick={handleDelete}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.SETTINGS.WEBHOOKS.DELETE.SUBMIT_BUTTON
            )}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DeleteWebhookModal;
