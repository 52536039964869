import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

// Icons
import DoneIcon from "@mui/icons-material/Done";

// styles
import "./styles.scss";

const index = ({ activePlan, setActivePlan, currentPlan, planRenewsAt }) => {
  return (
    <Box className="starterPlanWrapper">
      <Box className="starterPlanContent">
        <Typography>
          Your {currentPlan?.name || ""} plan will renew on {planRenewsAt}
        </Typography>
        <Typography>{MESSAGES.ACCOUNT.PLANS.HEADER.HEADING}</Typography>
      </Box>
      <FormGroup className="plansCheckWrapper">
        <FormControlLabel
          className="checkBoxLabel"
          control={
            <Checkbox
              className={
                activePlan === "monthly"
                  ? `customCheckbox checked`
                  : `customCheckbox`
              }
              // className="customCheckbox"
              onClick={() => setActivePlan("monthly")}
              icon={<Box className="notChecked"></Box>}
              checkedIcon={<DoneIcon />}
              checked={activePlan === "monthly"}
            />
          }
          label="Monthly"
        />
        <FormControlLabel
          className="checkBoxLabel"
          control={
            <Checkbox
              className={
                activePlan === "yearly"
                  ? `customCheckbox checked`
                  : `customCheckbox`
              }
              onClick={() => setActivePlan("yearly")}
              icon={<Box className="notChecked"></Box>}
              checkedIcon={<DoneIcon />}
              checked={activePlan === "yearly"}
            />
          }
          label="Annually"
        />
        {activePlan === "yearly" && (
          <Typography>{MESSAGES.ACCOUNT.PLANS.HEADER.FREE_MONTHS}</Typography>
        )}
      </FormGroup>
    </Box>
  );
};

export default index;
