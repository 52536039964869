import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Utils
import { MESSAGES } from "~/utils/message";

// Actions
import {
  doublicateTemplate,
  getAllTemplates,
} from "~/redux/actions/template-builder";
import { failure, success } from "~/redux/actions/snackbar-actions";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CircularProgress, Input } from "@mui/material";

// Icons
import { ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/**
 * DuplicateTemplateModal Component
 * A modal for duplicating a template.
 *
 * @param {boolean} open - Boolean indicating whether the modal is open.
 * @param {Function} handleClose - Function to handle the closing of the modal.
 * @param {Object} selectedRecord - The selected template record to duplicate.
 * @returns {JSX.Element} The duplicate template modal component.
 *
 */
const DuplicateTemplateModal = ({ open = false, handleClose, selectedRecord }) => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templateReducer.templates);

  // Handle the duplication of the template
  const handleDuplicate = async () => {
    const trimedTitle = title.trim();
    const errorText = !trimedTitle
      ? MESSAGES.TEMPLATE.NAME_REQUIRED
      : trimedTitle.length > 50
      ? MESSAGES.TEMPLATE.NAME_LESS_50
      : "";
    if (errorText) {
      dispatch(failure(errorText));
      return;
    }
    setLoading(true);
    const response = await doublicateTemplate(selectedRecord.id, {
      title: trimedTitle,
    });
    setLoading(false);

    if (response.status === 200) {
      dispatch(success(response.data.message));
      dispatch(getAllTemplates(templates.page, templates.perPage));
      handleCloseDialog();
    } else {
      dispatch(failure(response.data.message));
    }
  };

  useEffect(() => {
    setTitle(`${selectedRecord.title} (Copy)`);
  }, []);

  // Close the modal
  const handleCloseDialog = () => handleClose(false);

  return (
    <BootstrapDialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="duplicateModalWrapper"
    >
      <DialogTitle
        className="duplicateModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        Duplicate Template
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        disabled={loading}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          "&:disabled": { opacity: 0.5 },
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent className="duplicateModalContent">
        <Typography>Template Name</Typography>
        <Input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={selectedRecord.title}
        />
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button disabled={loading} autoFocus onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button disabled={loading} autoFocus onClick={handleDuplicate}>
          {loading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "25px !important",
                height: "25px !important",
              }}
            />
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DuplicateTemplateModal;
