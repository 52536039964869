import React, { useEffect, useRef } from "react";

// Hooks
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isSystemAdmin } from "~/hooks/permissions";

// Actions
import { LOGOUT } from "~/redux/actions/action-types";

// Utils
import {
  HEADERS_DROPDOWN,
  ADMIN_HEADERS_DROPDOWN,
} from "~/utils/constants";

// Mui Component
import { Box, Typography } from "@mui/material";


const index = (props) => {
  const dropDownRef = useRef(null);
  const { setShowDropDown, dropValue, isAdminMode, userName } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let currentDropdown = isAdminMode ? ADMIN_HEADERS_DROPDOWN : HEADERS_DROPDOWN;

  currentDropdown = currentDropdown.filter(
    (list) => isSystemAdmin() || list.name !== "Admin Portal"
  );

  const handleAfterClick = (fn, name) => {
    if (name === "Logout") {
      dispatch({ type: LOGOUT });
    }
    fn((path) => navigate(path));
    setShowDropDown(false);
  };

  const handleClickAway = () => {
    setOpen(false); // Close the dropdown when clicking away
  };

  useEffect(() => {
    let counter = 0;
    const handleClickOutside = (event) => {
      if (
        counter == 1 &&
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target)
      ) {
        setShowDropDown(false);
      } else {
        counter = 1;
      }
    };

    if (dropValue == true) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropValue]);

  return (
    <>
      <Box
        ref={dropDownRef}
        className={
          isAdminMode ? "dropDownWrapper adminDropdown" : "dropDownWrapper"
        }
      >
        {userName && (
          <Typography
            sx={{
              display: {
                md: "none",
                sm: "block",
                borderBottom: "0.5px solid rgba(84, 84, 84, 0.5)",
                padding: "5px 0px 10px",
                textAlign: "center"
              },
            }}
          >
            {userName &&
              (userName.length > 20 ? `${userName.slice(0, 15)}...` : userName)}
          </Typography>
        )}
        {currentDropdown.map((list) => {
          return (
            <Box
              className="dropDownItem"
              key={list?.id}
              onClick={() => handleAfterClick(list.func, list.name)}
            >
              <div className="imgOuter">
                <img src={list?.image} alt={list?.name} />
              </div>
              <Typography>{list?.name}</Typography>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default index;
