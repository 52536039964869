import React, { useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { changeAutoFunding } from "~/redux/actions/stripe-actions";
import { getUserData } from "~/redux/actions/auth-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Components
import AddFunds from "~/components/Account/Wallet/AddFunds";
import ConfirmDialog from "~/components/Account/Wallet/ConfirmDialog/ConfirmDialog";

// Utils
import { balanceFormatter } from "~/utils/helperFunctions";
import { MESSAGES } from "~/utils/message";
import { ROLES } from "~/utils/constants";

// MUI Components
import {
  Box,
  Typography,
  Button,
  Tooltip,
  Skeleton,
  CircularProgress,
} from "@mui/material";

// Icons
import { BalanceCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BalanceCard = () => {
  const dispatch = useDispatch();
  const [fundsModal, setFundsModal] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    loading: false,
  });
  const user = useSelector((state) => state.userReducers?.user);
  const paymentMethods = useSelector((state) => state.stripeReducers.methods);
  const handleCloseDialog = () => {
    setDialog({ open: false, message: "", loading: false });
  };

  const isUser = !(user?.role?.slug === ROLES["ORGANIZATION_ADMIN"]);

  const handleOk = async () => {
    setDialog((prev) => ({ ...prev, loading: true }));
    const data = await changeAutoFunding();
    if (data.status === 200) {
      dispatch(getUserData());
      dispatch(
        success(
          MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING_DISABLED_SUCCESS,
          MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING
        )
      );
      setDialog({ open: false, message: "", loading: false });
    }
    setDialog((prev) => ({ ...prev, loading: false }));
  };
  return (
    <>
      {/* {user?.organization?.balance == undefined ? (
        <Skeleton variant="rectangular" className="balanceCardWrapper" />
      ) : ( */}
      <Box className="balanceCardWrapper">
        <Box className="balanceImage">
          <img src={BalanceCIcon} alt="balance" />
          <Box className="balanceAmount">
            <Typography>
              {MESSAGES.ACCOUNT.WALLET.STRIPE.YOUR_BALANCE}
            </Typography>
            <Typography>
              {balanceFormatter(user?.organization?.balance)}
            </Typography>
          </Box>
        </Box>
        <Box className="fundsBtn">
          <Tooltip
            title={
              isUser ? MESSAGES.ACCOUNT.WALLET.STRIPE.UNAUTHORIZED_ADD_FUND : ""
            }
          >
            <Button
              onClick={() => {
                if (isUser) {
                  return null;
                }
                if (paymentMethods.length == 0 && !isUser) {
                  dispatch(
                    failure(
                      MESSAGES.ACCOUNT.WALLET.STRIPE.PAYMENT_METHOD_REQUIRED
                    )
                  );
                  return;
                } else {
                  setFundsModal(true);
                }
              }}
              className={isUser ? "disabled" : ""}
            >
              {user?.organization?.balance == undefined ? (
                <CircularProgress
                  sx={{
                    width: "24px !important",
                    height: "24px !important",
                    color: "white",
                  }}
                />
              ) : (
                MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDS
              )}
            </Button>
          </Tooltip>
          <Typography>
            {MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING}:{" "}
            <span
              onClick={() => {
                if (isUser) return;
                if (user.organization.isAutoFundingEnabled) {
                  setDialog({
                    open: true,
                    message: `Are you sure you want to ${
                      user?.organization?.isAutoFundingEnabled
                        ? "disable"
                        : "enable"
                    } auto funding for orders?`,
                  });
                }
              }}
            >
              {user?.organization?.isAutoFundingEnabled
                ? MESSAGES.ACCOUNT.WALLET.STRIPE.ENABLED
                : MESSAGES.ACCOUNT.WALLET.STRIPE.DISABLED}
            </span>
          </Typography>
        </Box>
        {fundsModal && (
          <AddFunds
            open={fundsModal}
            handleOpen={() => {
              setFundsModal(true);
            }}
            handleClose={() => {
              setFundsModal(false);
            }}
          />
        )}
        {dialog.open && (
          <ConfirmDialog
            successButtonName="Yes"
            message={dialog.message}
            open={dialog.open}
            loading={dialog.loading}
            handleClose={handleCloseDialog}
            handleOk={handleOk}
          />
        )}
      </Box>
      {/* )} */}
    </>
  );
};

export default BalanceCard;
