//Actions
import { SET_PRODUCT_TYPES, SET_PRODUCTS_DATA, LOGOUT } from "~/redux/actions/action-types"

const initialState = {
    products: [],
    productTypes: [],
    types: []
};

const productReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PRODUCTS_DATA:
            return { ...state, products: payload };
        case SET_PRODUCT_TYPES:
            return { ...state, productTypes: payload, types: payload?.filter((prod) => prod?.windowed !== false).map((product, id) => { return { id, title: product.productType } }) };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { productReducers };