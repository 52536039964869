import {
    SET_ADMIN_TEMPLATE_CATEGORIES_DATA, SET_ADMIN_TEMPLATE_CATEGORIES_LOADER, LOGOUT
} from "~/redux/actions/action-types"

const initialState = {
    templateCategories: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalTemplateCategoriesInDB: null,
    loading: false,
    labels: [],
    applyFilters: false,
    filterSize: null,
    filters: {}
};

const adminTemplatesReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ADMIN_TEMPLATE_CATEGORIES_DATA:
            return {
                ...state, totalCategoriesInDB: payload.totalRecordsInDB, templateCategories: payload.rows, rowsCount: payload.count, totalTemplateCategoriesInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_ADMIN_TEMPLATE_CATEGORIES_LOADER:
            return { ...state, loading: payload };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { adminTemplatesReducers };