//Actions
import { SET_SUCCESS_SNACKBAR, SET_ERROR_SNACKBAR, CLEAR_SNACKBAR } from "~/redux/actions/action-types"

const initialState = {
    snackbar: {
        open: false,
        status: 'success',
        heading: '',
        message: '',
    },
};

const snackbarReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_SUCCESS_SNACKBAR:
            return { ...state, snackbar: { ...state.snackbar, ...payload, open: true, status: 'success' } };
        case SET_ERROR_SNACKBAR:
            return { ...state, snackbar: { ...state.snackbar, ...payload, open: true, status: 'error' } };
        case CLEAR_SNACKBAR:
            return { ...state, snackbar: { ...state.snackbar, open: false, status: 'success', message: '', heading: '' } };
        default:
            return state;
    }
};

export { snackbarReducers };