import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Action
import {
  createUser,
  getHubspotContactProperties,
} from "~/redux/actions/auth-actions";
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import {
  INDUSTRY_OPTIONS,
  COMPANY_OPTIONS,
  ROLE_OPTIONS,
  ABOUT_OPTIONS,
  PASSWORD_REGEX,
  EMAIL_REGEX,
  TERMS_URL,
} from "~/utils/constants";
import {
  validateForm,
  realTimeValidation,
} from "~/utils/formik-validation";
import { MESSAGES } from "~/utils/message";

// Component
import MultiSelect from "~/components/General/MultiSelect";

// MUI Components
import {
  Box,
  Grid,
  Input,
  InputLabel,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Container,
} from "@mui/material";

// Icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DoneIcon from "@mui/icons-material/Done";
import { SecondaryCIcon } from "~/assets/images";

// Styles
import "~/index.scss";

const CreateAccount = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [newsLetterCheck, setNewsLetterCheck] = useState(true);
  const [loading, setLoading] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [code, setCode] = useState(null);
  const [hsFields, setHsFields] = useState({
    what_industry_are_you_part_of_: INDUSTRY_OPTIONS,
    how_large_is_your_company_: COMPANY_OPTIONS,
    what_is_your_role_: ROLE_OPTIONS,
    how_did_you_hear_about_us_: ABOUT_OPTIONS,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // For Password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // For Confirm Password
  const handleClickShowConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required(MESSAGES.AUTH.FIRST_NAME_REQUIRED),
    lastName: yup.string().required(MESSAGES.AUTH.LAST_NAME_REQUIRED),
    industry: yup.string().required(MESSAGES.AUTH.INDUSTRY_REQUIRED),
    company: yup.string().required(MESSAGES.AUTH.COMPANY_REQUIRED),
    crm: yup.string().required(MESSAGES.AUTH.CRM_REQUIRED),
    role: yup.string().required(MESSAGES.AUTH.ROLE_REQUIRED),
    about: yup.string().required(MESSAGES.AUTH.ABOUT_REQUIRED),
    email: yup
      .string()
      .matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL)
      .required(MESSAGES.AUTH.EMAIL_REQUIRED),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, MESSAGES.AUTH.INVALID_PASSWORD)
      .required(MESSAGES.AUTH.INVALID_PASSWORD),
    confrimPassword: yup
      .string()
      .required(MESSAGES.AUTH.PASSWORD_MATCH_ERROR)
      .oneOf([yup.ref("password")], MESSAGES.AUTH.PASSWORD_MATCH_ERROR),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      industry: "",
      company: "",
      crm: "",
      role: "",
      about: "",
      email: "",
      password: "",
      confrimPassword: "",
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (searchParams.size > 0) {
      if (searchParams.get("token") && searchParams.get("invited")) {
        let paramsToken = searchParams.get("token");
        let invitedEmail = searchParams.get("invited");
        formik.setFieldValue(
          "email",
          decodeURIComponent((invitedEmail + "").replace(/\%20/g, "+"))
        );
        setCode(paramsToken);
      }
    }

    getHubspotContactProperties().then((properties) => {
      setHsFields((fields) => {
        const newFields = { ...fields };
        properties.forEach((pro) => {
          if (pro.options?.length) newFields[pro.name] = pro.options;
        });
        return newFields;
      });
    });
  }, []);

  const register = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        if (!termsCheck) {
          setTermsError(true);
        }
        return;
      }
      if (!termsCheck) {
        setTermsError(true);
        return;
      }
      const payload = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        password: formik.values.password.trim(),
        is_newsletter_subscribed: newsLetterCheck,
        secretKey: code,
        how_did_you_hear_about_us: formik.values.about,
        how_large_is_your_company: formik.values.company,
        what_crm_do_you_use_currently: formik.values.crm,
        what_industry_are_you_part_of: formik.values.industry,
        what_is_your_role: formik.values.role,
      };
      setLoading(true);
      const joinTeam = code;
      const request = await dispatch(createUser(payload, joinTeam));
      setLoading(false);
      if (request.message === "success") {
        return;
      } else {
        dispatch(
          failure(
            request?.response?.data?.data?.errors[0]?.msg ||
              request?.response?.data?.message ||
              MESSAGES.GENERAL_ERROR
          )
        );
        if (request?.response?.data?.data?.errors?.length) {
          let serverSideError = request?.response?.data?.data?.errors;
          serverSideError.forEach((error) => {
            formik.errors[error.path] = error.msg;
            formik.touched[error.path] = true;
          });
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.firstName = false;
    formik.touched.lastName = false;
    formik.touched.industry = false;
    formik.touched.company = false;
    formik.touched.crm = false;
    formik.touched.role = false;
    formik.touched.about = false;
    formik.touched.email = false;
    formik.touched.password = false;
    formik.touched.confrimPassword = false;
  }, []);

  // form select states

  const [formSelect, setFormSelect] = useState({
    industry: "",
    company: "",
    role: "",
    about: "",
  });

  const handleFormSelect = (field, newValue) => {
    formik.setFieldValue(field, newValue[0]);
    setFormSelect((prevState) => ({
      ...prevState,
      [field]: newValue,
    }));
  };

  return (
    <Box className="layoutBg" sx={{ height: "100%", minHeight: "102vh" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box className="imageWrapper">
              <img src={SecondaryCIcon} alt="OpenLetter" />
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className="formBG">
            <Box className="formWrapper">
              <Typography variant="h4">{MESSAGES.SIGN_UP.TITLE}</Typography>
              <form onSubmit={register}>
                {/* first name & last name */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                  <Box className="inputWrapper fullName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_FIRST_NAME}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={MESSAGES.SIGN_UP.INPUT_LABEL_FIRST_NAME}
                      name="firstName"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.firstName && formik.touched.firstName
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.firstName}
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.firstName}
                      </Typography>
                    )}
                  </Box>
                  <Box className="inputWrapper lastName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_LAST_NAME}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder={MESSAGES.SIGN_UP.INPUT_LABEL_LAST_NAME}
                      name="lastName"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.lastName && formik.touched.lastName
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.lastName}
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.lastName}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* first name & last name */}

                {/* industry & Company */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                  <Box className="inputWrapper fullName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_INDUSTRY}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <MultiSelect
                      name="industry"
                      options={hsFields.what_industry_are_you_part_of_}
                      selectedValue={formSelect?.industry}
                      setSelectedValue={(newValue) =>
                        handleFormSelect("industry", newValue)
                      }
                      placeHolderText={MESSAGES.SIGN_UP.INDUSTRY_PLACEHOLDER}
                      multiple={false}
                      component="signup"
                      error={
                        formik.touched.industry && formik.errors.industry
                          ? true
                          : false
                      }
                    />
                    {formik.touched.industry && formik.errors.industry && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.industry}
                      </Typography>
                    )}
                  </Box>
                  <Box className="inputWrapper lastName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_COMPANY}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <MultiSelect
                      options={hsFields.how_large_is_your_company_}
                      selectedValue={formSelect?.company}
                      setSelectedValue={(newValue) =>
                        handleFormSelect("company", newValue)
                      }
                      placeHolderText={MESSAGES.SIGN_UP.COMPANY_PLACEHOLDER}
                      multiple={false}
                      component="signup"
                      error={
                        formik.touched.company && formik.errors.company
                          ? true
                          : false
                      }
                    />
                    {formik.touched.company && formik.errors.company && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.company}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* industry & Company */}

                {/* CRM */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                  <Box className="inputWrapper fullName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_CRM}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type="text"
                      placeholder="Type your response here ...."
                      name="crm"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.crm && formik.touched.crm
                          ? "invalid formInput"
                          : "formInput"
                      }
                      value={formik.values.crm}
                    />
                    {formik.touched.crm && formik.errors.crm && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.crm}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* CRM */}

                {/* Role & About */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                  <Box className="inputWrapper fullName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_ROLE}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <MultiSelect
                      options={hsFields.what_is_your_role_}
                      selectedValue={formSelect?.role}
                      setSelectedValue={(newValue) =>
                        handleFormSelect("role", newValue)
                      }
                      placeHolderText={MESSAGES.SIGN_UP.ROLE_PLACEHOLDER}
                      multiple={false}
                      component="signup"
                      error={
                        formik.touched.role && formik.errors.role ? true : false
                      }
                    />
                    {formik.touched.role && formik.errors.role && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.role}
                      </Typography>
                    )}
                  </Box>
                  <Box className="inputWrapper lastName">
                    <InputLabel className="formLabel">
                      {MESSAGES.SIGN_UP.INPUT_LABEL_ABOUT}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <MultiSelect
                      options={hsFields.how_did_you_hear_about_us_}
                      selectedValue={formSelect?.about}
                      setSelectedValue={(newValue) =>
                        handleFormSelect("about", newValue)
                      }
                      placeHolderText={MESSAGES.SIGN_UP.ABOUT_PLACEHOLDER}
                      multiple={false}
                      component="signup"
                      error={
                        formik.touched.about && formik.errors.about
                          ? true
                          : false
                      }
                    />
                    {formik.touched.about && formik.errors.about && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.about}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {/* Role & About */}

                <Box className="inputWrapper">
                  <InputLabel className="formLabel">
                    {MESSAGES.SIGN_UP.INPUT_LABEL_EMAIL}
                    <span style={{ position: "absolute", top: "-3px" }}>*</span>
                  </InputLabel>
                  <Input
                    type="text"
                    placeholder={MESSAGES.SIGN_UP.EMAIL_PLACEHOLDER}
                    autoComplete="off"
                    name="email"
                    disabled={code}
                    onChange={formik.handleChange}
                    className={
                      formik.errors.email && formik.touched.email
                        ? "invalid formInput"
                        : "formInput"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Box className="inputWrapper">
                  <InputLabel className="formLabel">
                    {MESSAGES.SIGN_UP.INPUT_LABEL_PASSWORD}
                    <span style={{ position: "absolute", top: "-3px" }}>*</span>
                  </InputLabel>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder={MESSAGES.SIGN_UP.INPUT_LABEL_PASSWORD}
                    name="password"
                    onChange={formik.handleChange}
                    className={
                      formik.errors.password && formik.touched.password
                        ? "invalid formInput"
                        : "formInput"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                    value={formik.values.password.trim()}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className="passwordIconBtn"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Typography className="errorMessage">
                      <sup>*</sup>
                      {formik.errors.password}
                    </Typography>
                  ) : (
                    <Typography className="passwordMessage">
                      {MESSAGES.AUTH.INVALID_PASSWORD}
                    </Typography>
                  )}
                </Box>
                <Box className="inputWrapper">
                  <InputLabel className="formLabel">
                    {MESSAGES.SIGN_UP.INPUT_LABEL_CONFIRM_PASSWORD}
                    <span style={{ position: "absolute", top: "-3px" }}>*</span>
                  </InputLabel>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confrimPassword"
                    placeholder={MESSAGES.SIGN_UP.INPUT_LABEL_CONFIRM_PASSWORD}
                    onChange={formik.handleChange}
                    className={
                      formik.errors.confrimPassword &&
                      formik.touched.confrimPassword
                        ? "invalid formInput"
                        : "formInput"
                    }
                    onBlur={realTimeValidation ? formik.handleBlur : undefined}
                    onKeyDown={
                      realTimeValidation ? formik.handleBlur : undefined
                    }
                    value={formik.values.confrimPassword.trim()}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          edge="end"
                          className="passwordIconBtn"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.touched.confrimPassword &&
                    formik.errors.confrimPassword && (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.confrimPassword}
                      </Typography>
                    )}
                </Box>
                <FormGroup>
                  <FormControlLabel
                    className="checkBoxLabel"
                    control={
                      <Checkbox
                        className={
                          termsCheck
                            ? `customCheckbox checked`
                            : `customCheckbox`
                        }
                        onClick={() => setTermsCheck(!termsCheck)}
                        icon={<Box className="notChecked"></Box>}
                        checkedIcon={<DoneIcon />}
                      />
                    }
                    label={
                      <Typography
                        className={
                          !termsCheck && termsError
                            ? `termsCheck errorCheck`
                            : `termsCheck`
                        }
                      >
                        {MESSAGES.SIGN_UP.AGREE_TEXT}
                        <NavLink to={TERMS_URL} target="_blank">
                          {MESSAGES.SIGN_UP.TERMS_TEXT}
                        </NavLink>
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    className="checkBoxLabel"
                    control={
                      <Checkbox
                        className={
                          newsLetterCheck
                            ? `customCheckbox checked`
                            : `customCheckbox`
                        }
                        onClick={() => setNewsLetterCheck(!newsLetterCheck)}
                        icon={<Box className="notChecked"></Box>}
                        checkedIcon={<DoneIcon />}
                        checked={newsLetterCheck}
                      />
                    }
                    label={MESSAGES.AUTH.NEWS_LETTER_TEXT}
                  />
                </FormGroup>
                <Button
                  type="submit"
                  onClick={() => {
                    validateForm(formik);
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : code ? (
                    MESSAGES.SIGN_UP.JOIN_BUTTON
                  ) : (
                    MESSAGES.SIGN_UP.SUBMIT_BUTTON
                  )}
                </Button>
              </form>
              <Typography className="account">
                {MESSAGES.SIGN_UP.SIGN_IN_TEXT}
                <span
                  className="signin-btn"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <>{MESSAGES.SIGN_UP.SIGN_IN_BUTTON}</>
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CreateAccount;
