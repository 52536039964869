import React, { forwardRef } from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";

// Actions
import { closeSnackbar } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { Typography, Box } from "@mui/material";

// Styles
import "./styles.scss";

const GlobalSnackbar = () => {
  const vertical = "top";
  const horizontal = "right";

  const dispatch = useDispatch();

  const open = useSelector((state) => state.snackbarReducers.snackbar.open);
  const status = useSelector((state) => state.snackbarReducers.snackbar.status);
  const heading = useSelector(
    (state) => state.snackbarReducers.snackbar.heading
  );
  const message = useSelector(
    (state) => state.snackbarReducers.snackbar.message
  );

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const Transition = (props) => {
    return <Slide {...props} direction="left" />;
  };

  const close = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(closeSnackbar());
  };

  if (!open) return;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={5000}
      onClose={close}
      TransitionComponent={Transition}
    >
      {message !== null ?
        <Alert onClose={close} severity={status} className="alertWrapper">
          <Box>
            <Typography variant="h4">
              {heading || MESSAGES.SNACKBAR.HEADING}
            </Typography>
            <Typography>{message}</Typography>
          </Box>
        </Alert> : <div></div>}

    </Snackbar>
  );
};

export default GlobalSnackbar;
