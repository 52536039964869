// Request util for api call
import request from "~/utils/request";

// Actions Types
import { SET_ORDERS_DATA, SET_ORDERS_LOADER, SET_ORDERS_FILTERS_LABELS, SET_ORDERS_DETAIL_CONTACTS_DATA, SET_ORDERS_DETAIL_OVERVIEW_DETAIL, SET_ORDER_DETAIL_LOADER, CONTACTS_SEARCH, SET_ORDER_DETAIL_CONTACTS_LOADER } from "./action-types"

// Utiils
import { dateFormat } from "~/utils/date-format";
import { DEFAULT_PAGINATION } from "~/utils/constants";
let isFetching = false;

const fetchOrders = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_ORDERS_LOADER, payload: true });

        let url = '/orders';

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'createdDate' || key === 'scheduledDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            let date = Object?.entries(value)
                                .map(([nestedKey, nestedValue]) => `${JSON.stringify(nestedKey)}:${JSON.stringify(nestedValue)}`)
                                .join(', ');
                            queryPayload[key] = `{${date}}`;
                        }
                    }
                    else if (value.length) {
                        queryPayload[key] = `${JSON.stringify(value)}`;
                    }
                })
        }

        const response = await request.get(url, queryPayload);

        if (response.status === 200) {
            const combinedData = response.data.data.rows.map((order) => ({
                id: order.id || ' -',
                date: dateFormat(order.campaign.createdAt, true) || ' -',
                status: order.status || ' -',
                source: order.source || ' -',
                productType: order.product.productType || ' -',
                quantity: order.quantity || ' -',
                deliverableQuantity: order.deliverableQuantity || ' -',
                cost: order.cost || 0,
                scheduledDate: dateFormat(order.campaign.deliveryDate, true) || ' -',
                paymentStatus: order.paymentStatus || ' -',
                checked: false,
                createdBy: order?.creator?.fullName || ' -',
            }));
            const orders = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
            }
            dispatch({ type: SET_ORDERS_DATA, payload: orders });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ORDERS_LOADER, payload: false });
    }
}

const fetchFiltersLabels = () => async (dispatch) => {
    try {
        const labels = await request.get("orders/filters-data");
        let modifiedLabels = {}
        Object.keys(labels.data.data).forEach((key) => {
            const filters = Object.values(labels.data.data[key]).map((item, id) => ({
                id,
                title: item
            }));
            modifiedLabels[key] = filters;
        });
        dispatch({ type: SET_ORDERS_FILTERS_LABELS, payload: modifiedLabels });
    } catch (error) {
        return error;
    }
};

const fetchOrderCost = (payload) => async () => {
    try {
        const cost = await request.post("orders/calculate-cost", payload);
        return cost
    } catch (error) {
        return error.response;
    }
};

const creatOrder = (payload) => async () => {
    try {
        const order = await request.post("orders", payload);
        return order
    } catch (error) {
        return error.response;
    }
}

const fetchOrderContactDetails = (payload) => async (dispatch) => {
    try {
        if (isFetching) {
            return;
        }

        isFetching = true;

        dispatch({ type: SET_ORDER_DETAIL_CONTACTS_LOADER, payload: true });

        let url = `orders/detail/contacts/${payload.id}`;

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'deliveredDate') {
                        if (value) {
                            let date = Object?.entries(value)
                                .map(([nestedKey, nestedValue]) => `${JSON.stringify(nestedKey)}:${JSON.stringify(nestedValue)}`)
                                .join(', ');
                            queryPayload[key] = `{${date}}`;
                        }
                    }
                    else if (value.length) {
                        queryPayload[key] = `${JSON.stringify(value)}`;
                    }
                })
        }

        const response = await request.get(url, queryPayload);
        if (response.status === 200) {

            const combinedData = response.data.data.rows.map((order) => ({
                ...order,
                checked: false,
            }));
            const ordersContacts = {
                rows: combinedData,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
            }
            dispatch({ type: SET_ORDERS_DETAIL_CONTACTS_DATA, payload: ordersContacts });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ORDER_DETAIL_CONTACTS_LOADER, payload: false });
        isFetching = false;
    }
}

const fetchOrderOverviewDetails = (payload) => async (dispatch) => {
    try {

        dispatch({ type: SET_ORDER_DETAIL_LOADER, payload: true });

        const stepperDetails = await request.get(`/orders/${payload.id}`);
        const overviewDetails = await request.get(`/orders/detail/${payload.id}`);
        const analyticDetails = await request.get(`/orders/detail/analytics/${payload.id}`)

        const combinedData = {
            stepper: stepperDetails.data.data,
            overview: overviewDetails.data.data, analytics: analyticDetails.data.data, analytics: analyticDetails.data.data
        }

        dispatch({ type: SET_ORDERS_DETAIL_OVERVIEW_DETAIL, payload: combinedData });

    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_ORDER_DETAIL_LOADER, payload: false });
    }
};


const searchAndAdvanceChange = (name, value) => (dispatch) => {
    dispatch({ type: CONTACTS_SEARCH, payload: { name, value } });
};

const cancelOrder = (payload) => async () => {
    try {
        const order = await request.post("orders/cancel-order", payload);
        return order
    } catch (error) {
        return error.response;
    }
}

const payAndProcess = (payload) => async () => {
    try {
        const order = await request.post("orders/pay-and-process", payload);
        return order
    } catch (error) {
        return error.response;
    }
}

const removeContact = (payload) => async () => {
    try {
        const order = await request.post("orders/remove-contact", payload);
        return order
    } catch (error) {
        return error.response;
    }
}

const orderBulkActions = async (payload, details = false) => {
    try {
        const queryPayload = {}


        queryPayload.ids = payload.ids;
        queryPayload.action = payload.action;

        if (details) {
            queryPayload.orderId = payload.orderId;
        }

        if (payload?.search?.length || payload?.applyFilters) {
            queryPayload.filters = {}
        }

        if (payload?.search?.length) {
            queryPayload.filters.search = payload.search;
        }

        if (payload?.applyFilters) {
            const filterParams = Object.entries(payload.filters)
                .map(([key, value]) => {
                    if (key === 'createdDate' || key === 'scheduledDate') {
                        // If the value is an object, iterate over its properties
                        if (value) {
                            queryPayload.filters[key] = value;
                        }
                    }
                    else if (value.length) {
                        queryPayload.filters[key] = value;
                    }
                })
        }

        const orderBulk = await request.post('orders/bulk-actions', queryPayload);
        return orderBulk;
    } catch (error) {
        return error.response;
    }
}

export { fetchOrders, fetchFiltersLabels, fetchOrderCost, creatOrder, fetchOrderContactDetails, fetchOrderOverviewDetails, searchAndAdvanceChange, cancelOrder, payAndProcess, removeContact, orderBulkActions }
