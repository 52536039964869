import React from "react";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// Icons
import { ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

// Assets
import Trash from "~/assets/images/contact-details/delete.svg";
import { MESSAGES } from "~/utils/message";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteModal = (props) => {
  const { openModal, handleCloseModal, deleteContact } = props;

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="contactDeleteModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent dividers className="deleteModalContent">
        <img src={Trash} alt="delete" />
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.CONTACTS.DELETE.TITLE}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.CONTACTS.DELETE.HEADING}
          </Typography>
          <Typography className="footerText">
            {MESSAGES.CONTACTS.DELETE.MESSAGE}
          </Typography>
        </Box>
        <Box className="deleteModalBtns">
          <Button onClick={handleCloseModal}>
            {" "}
            {MESSAGES.CONTACTS.DELETE.CANCEL_BUTTON}
          </Button>
          <Button onClick={deleteContact}>
            {MESSAGES.CONTACTS.DELETE.SUBMIT_BUTTON}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DeleteModal;
