import React, { useState, useEffect } from "react";

// Libraries and packages
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Action
import { updateUserPassworrd } from "~/redux/actions/auth-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

//Utils
import { getResetCode, getResetToken } from "~/utils/auth";
import { PASSWORD_REGEX } from "~/utils/constants";

// Utils
import {
  validateForm,
  realTimeValidation,
} from "~/utils/formik-validation";
import { MESSAGES } from "~/utils/message";

// Icons
import { LoginSCIcon } from "~/assets/images";
import {
  Box,
  Grid,
  Input,
  InputLabel,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
  Container,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Styles
import "~/index.scss";


const UpdatePassword = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // For Confirm Password
  const handleClickShowConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .matches(PASSWORD_REGEX, MESSAGES.AUTH.INVALID_PASSWORD)
      .required(MESSAGES.AUTH.INVALID_PASSWORD),
    confrimPassword: yup
      .string()
      .required(MESSAGES.AUTH.PASSWORD_MATCH_ERROR)
      .oneOf([yup.ref("password")], MESSAGES.AUTH.PASSWORD_MATCH_ERROR),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confrimPassword: "",
    },
    validationSchema: validationSchema,
  });

  const updatePassword = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const token = getResetToken();
      const code = getResetCode();
      const payload = {
        password: formik.values.password.trim(),
        token,
        code,
      };
      setLoading(true);
      const request = await dispatch(updateUserPassworrd(payload));
      setLoading(false);
      if (request.message === "success") {
        dispatch(
          success(
            MESSAGES.AUTH.PASSWORD_UPDATED,
            MESSAGES.AUTH.PASSWORD_UPDATED_HEADING
          )
        );
        navigate("/login");
      } else {
        dispatch(
          failure(request?.response?.data?.message || MESSAGES.GENERAL_ERROR)
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.touched.password = false;
    formik.touched.confrimPassword = false;
  }, []);

  return (
    <Box className="layoutBg" sx={{ height: "100%", minHeight: "102vh" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box className="imageWrapper">
              <img src={LoginSCIcon} alt="OpenLetter" />
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className="formBG">
            <Box className="formWrapper updateForm">
              <Box className="forgotFormHeadings">
                <Typography variant="h4">
                  {MESSAGES.RESET_PASSWORD.TITLE}
                </Typography>
              </Box>
              <form className="updateFormWrapper" onSubmit={updatePassword}>
                <Box
                  className="resetFormContent"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box className="inputWrapper updateFormInput">
                    <InputLabel className="formLabel">
                      {MESSAGES.RESET_PASSWORD.INPUT_LABEL_PASSWORD}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder={MESSAGES.RESET_PASSWORD.INPUT_LABEL_PASSWORD}
                      name="password"
                      onChange={formik.handleChange}
                      className={
                        formik.errors.password && formik.touched.password
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.password.trim()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            className="passwordIconBtn"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <Typography className="errorMessage">
                        <sup>*</sup>
                        {formik.errors.password}
                      </Typography>
                    ) : (
                      <Typography className="passwordMessage">
                        {MESSAGES.AUTH.INVALID_PASSWORD}
                      </Typography>
                    )}
                  </Box>
                  <Box className="inputWrapper updateFormInput">
                    <InputLabel className="formLabel">
                      {MESSAGES.RESET_PASSWORD.INPUT_LABEL_CONFIRM_PASSWORD}
                      <span style={{ position: "absolute", top: "-3px" }}>
                        *
                      </span>
                    </InputLabel>
                    <Input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confrimPassword"
                      placeholder={
                        MESSAGES.RESET_PASSWORD.INPUT_LABEL_CONFIRM_PASSWORD
                      }
                      onChange={formik.handleChange}
                      className={
                        formik.errors.confrimPassword &&
                        formik.touched.confrimPassword
                          ? "invalid formInput"
                          : "formInput"
                      }
                      onBlur={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      onKeyDown={
                        realTimeValidation ? formik.handleBlur : undefined
                      }
                      value={formik.values.confrimPassword.trim()}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                            className="passwordIconBtn"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.confrimPassword &&
                      formik.errors.confrimPassword && (
                        <Typography className="errorMessage">
                          <sup>*</sup>
                          {formik.errors.confrimPassword}
                        </Typography>
                      )}
                  </Box>
                </Box>
                <Button
                  type="submit"
                  onClick={() => {
                    validateForm(formik);
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    MESSAGES.RESET_PASSWORD.SUBMIT_BUTTON
                  )}
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default UpdatePassword;
