import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";
import {
  createContact,
  fetchContactLabels,
} from "~/redux/actions/contacts-actions.js";

//Utils
import { MESSAGES } from "~/utils/message.js";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation";
import { EMAIL_REGEX } from "~/utils/constants.js";

// Components
import ModalInput from "./ModalInput/index.jsx";
import CreateTagModal from "~/components/Settings/Tags/CreateTag/index.jsx";

//MUI Component
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, Grid, Container, CircularProgress } from "@mui/material";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PlusIconCIcon } from "~/assets/images/index.jsx";

// styles
import "./styles.scss";

const CreateContacts = (props) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createTagModal, setCreateTagModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPropertyAddressEnabled = useSelector(
    (state) =>
      state?.generalSettingsReducers?.generalSettings
        ?.propertyAddressStrictness === "Include" || false
  );

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .test({
        test: function (value) {
          const { companyName } = this.parent;
          return !!value || !!companyName;
        },
        message: MESSAGES.CONTACTS.CREATE.FIRST_NAME_OR_COMPANY_REQUIRED,
      })
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.FIRST_NAME_LIMIT)
      .notRequired(),
    companyName: yup
      .string()
      .max(100, MESSAGES.CONTACTS.CREATE.COMPANY_MAX_LENGTH)
      .test({
        test: function (value) {
          const { firstName } = this.parent;
          return !!value || !!firstName;
        },
        message: MESSAGES.CONTACTS.CREATE.FIRST_NAME_OR_COMPANY_REQUIRED,
      })
      .notRequired(),
    lastName: yup
      .string()
      .max(30, MESSAGES.ACCOUNT.PROFILE.EDIT_NAME.LAST_NAME_LIMIT),
    address1: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.ADDRESS_1__REQUIRED),
    address2: yup
      .string()
      .max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_2_LIMIT),
    city: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.CITY_REQUIRED),
    state: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.STATE_REQUIRED),
    zip: yup
      .string()
      .max(10, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LIMIT)
      .required(MESSAGES.CONTACTS.CREATE.ZIP_REQUIRED),
    phoneNo: yup
      .string()
      .max(20, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.PHONE_LIMIT)
      .nullable(true),
    email: yup.string().matches(EMAIL_REGEX, MESSAGES.AUTH.INVALID_EMAIL),

    // property address fields
    propertyAddress: yup
      .string().max(100, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ADDRESS_1_LIMIT),
    propertyState: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.STATE_LIMIT),
    propertyCity: yup
      .string()
      .max(40, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.CITY_LIMIT),
    propertyZip: yup
      .string()
      .max(10, MESSAGES.ACCOUNT.PROFILE.EDIT_ACCOUNT_INFO.ZIP_LIMIT),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phoneNo: "",
      email: "",
      propertyAddress: "",
      propertyState: "",
      propertyCity: "",
      propertyZip: "",
    },
    validationSchema: validationSchema,
  });

  const tags = useSelector((state) => state.contactsReducers.labels);

  // Sort the tags alphabetically
  const sortedTags = [...tags].sort((a, b) => a?.title.localeCompare(b?.title));

  const tagsOptions = sortedTags
    ?.map((tag) => {
      return {
        id: tag.id,
        title: tag.title,
      };
    })
    .concat({
      id: "-1",
      title: "Create New Tag",
      custom: true,
      icon: PlusIconCIcon,
      func: () => setCreateTagModal(true),
    });

  const onClose = () => {
    navigate("/contacts");
  };

  const create = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const payload = {...formik.values};
      selectedTags.length ? (payload.tags = selectedTags) : undefined;
      payload?.email?.length ? payload?.email : (payload.email = undefined);

      //<editor-fold desc="Property address fields">
      payload.propertyAddress = payload?.propertyAddress?.trim() || undefined ;
      payload.propertyState = payload?.propertyState?.trim() || undefined ;
      payload.propertyCity = payload?.propertyCity?.trim() || undefined ;
      payload.propertyZip = payload?.propertyZip?.trim() || undefined ;
      //</editor-fold>

      setLoading(true);
      const response = await dispatch(createContact(payload));
      if (response.status === 200) {
        dispatch(success(response.data.message));
        setLoading(false);
        onClose();
      } else {
        dispatch(
          failure(
            response?.data?.data?.errors[0]?.msg || response?.data?.message
          )
        );
      }
    } catch (error) {
      dispatch(failure(error.message));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(fetchContactLabels());
    formik.resetForm();
    resetValidation();
    setSelectedTags([]);
    // Reset all formik.touched values to false
    Object.keys(formik.initialValues).forEach((fieldName) => {
      formik.setFieldTouched(fieldName, false);
    });
  }, []);

  return (
    <Container maxWidth="xxl">
      <Grid container className="createContactWrapper" dividers={"true"}>
        <Grid item lg={1} md={2} sm={2} xs={12}>
          <Button className="contactsBackBtn" onClick={onClose}>
            <ArrowBackIcon />
            {MESSAGES.CONTACTS.CONTACT_BACK_ARROW}
          </Button>
        </Grid>
        <Grid item lg={11} md={10} sm={10} xs={12}>
          <form onSubmit={create}>
            <Box className="createContactHeader">
              <Typography gutterBottom variant="h4">
                {MESSAGES.CONTACTS.CREATE.TITLE}
              </Typography>
            </Box>
            <Box className="createContent">
              {/* first row */}
              <Grid container className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.FIRST_NAME_LABEL}
                    type="text"
                    name="firstName"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.firstName}
                    updateValue={formik.handleChange}
                    isCreate={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.LAST_NAME_LABEL}
                    name="lastName"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.lastName}
                    isCreate={true}
                  />
                </Grid>
              </Grid>
              {/* second row */}
              <Grid container className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.COMPANY_LABEL}
                    name="companyName"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.companyName}
                    isCreate={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.ADDRESS_1_LABEL}
                    name="address1"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.address1}
                    isCreate={true}
                  />
                </Grid>
              </Grid>
              {/* third row */}
              <Grid container className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.ADDRESS_2_LABEL}
                    name="address2"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.address2}
                    isCreate={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.CITY_LABEL}
                    name="city"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.city}
                    isCreate={true}
                  />
                </Grid>
              </Grid>
              {/* fourth row */}
              <Grid container className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.STATE_LABEL}
                    name="state"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.state}
                    isCreate={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.ZIP_LABEL}
                    name="zip"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.zip}
                    isCreate={true}
                  />
                </Grid>
              </Grid>
              {/* fifth row */}
              <Grid container className="gridCustomize">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.PHONE_LABEL}
                    name="phoneNo"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.phoneNo}
                    isCreate={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.EMAIL_LABEL}
                    name="email"
                    type="text"
                    formik={formik}
                    realTimeValidation={realTimeValidation}
                    value={formik.values.email}
                    isCreate={true}
                  />
                </Grid>
              </Grid>

              {isPropertyAddressEnabled && (
                <>
                  {/* sixth row */}
                  <Grid container className="gridCustomize">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_ADDRESS}
                        name="propertyAddress"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyAddress}
                        isCreate={true}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_CITY}
                        name="propertyCity"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyCity}
                        isCreate={true}
                      />
                    </Grid>
                  </Grid>
                  {/* seventh row */}
                  <Grid container className="gridCustomize">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_STATE}
                        name="propertyState"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyState}
                        isCreate={true}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <ModalInput
                        label={MESSAGES.CONTACTS.CREATE.PROPERTY_ZIP}
                        name="propertyZip"
                        type="text"
                        formik={formik}
                        realTimeValidation={realTimeValidation}
                        value={formik.values.propertyZip}
                        isCreate={true}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {/* eight row */}
              <Grid container className="gridCustomize">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ModalInput
                    label={MESSAGES.CONTACTS.CREATE.TAGS_LABEL}
                    name="tags"
                    isMultiSelect={true}
                    options={tagsOptions}
                    value={selectedTags}
                    setValue={setSelectedTags}
                    isCreate={true}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box className="contactModalBtns">
              <Button onClick={onClose}>
                {MESSAGES.ORDERS.CREATE.CONTACTS.CANCEL_BUTTON}
              </Button>
              <Button
                type="submit"
                className="btnSave"
                onClick={() => validateForm(formik)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ORDERS.CREATE.CONTACTS.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
      <CreateTagModal
        openModal={createTagModal}
        handleCloseModal={() => setCreateTagModal(false)}
        setSelectedTags={setSelectedTags}
        setTags={true}
      />
    </Container>
  );
};

export default CreateContacts;
