export const parseJson = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {}
};

export const formatNumberWithMaxDecimals = (number, decimalPoints = 3) => {
  return number.toString().split('.')[1] || ''.length > decimalPoints
    ? '$' + parseFloat(number).toFixed(decimalPoints)
    : number === ' -'
    ? number
    : '$' + number;
};

export const balanceFormatter = (balance, decimalPoints = 2) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPoints,
  });
  if (balance) {
    const result = formatter.format(balance);
    return result;
  } else {
    return "$0.00";
  }
};

export const numberWithComma = (x) => {
  if (x === '' || x === undefined) {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isSlackWebhook = (url) => {
  return url.includes('hooks.slack.com');
};

export const percentager = (value, total) => {
  if (total === 0) {
    return 0;
  }
  return ((value / total) * 100).toFixed(1);
};

export const isNumberString = (str) => {
  return /^\d+$/.test(str);
};

export const transformData = (obj) => {
  Object.keys(obj).forEach((key) => {
    const data = JSON.parse(obj[key]);
    obj[key] = data;
  });
  return obj;
};

export const stringLength = (str, len=30) => {
  if (str?.length > len) {
    return true;
  } else {
    return false;
  }
};

export const copyToClipboard = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {})
    .catch((err) => console.error('Failed to copy text: ', err));
};

export const trimName = (name) => {
  const words = name.trim().split(/\s+/);
  return words.length > 1 ? words.join(" ") : words[0];
};

export const getParsedObject = (data) => {
  try {
    return typeof data === 'string' ? JSON.parse(data) : data;
  } catch (error) {
    return error;
  }
};

export const getEvent = (data) => {
  const requestBody = getParsedObject(data?.value?.request?.body);

  if (isSlackWebhook(data?.value?.request?.url)) {
    const slackText = getParsedObject(requestBody?.text);
    return slackText?.event || ' -';
  }

  return requestBody?.event || ' -';
};