import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { downgradePlan } from "~/redux/actions/stripe-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";
import { getUserData } from "~/redux/actions/auth-actions";

//Utils
import { balanceFormatter } from "~/utils/helperFunctions";
import { MESSAGES } from "~/utils/message";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Grid, CircularProgress } from "@mui/material";

// Icons
import { ModalClosePlansCIcon, DowngradeCIcon, CrossPlansCIcon } from "~/assets/images";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DowngradeModal = (props) => {
  const { handleClose, open, selectedPlan, currentPlanDetails, planDetails } =
    props;
  const [requestLoader, setRequestLoader] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setRequestLoader(true);
      const response = await downgradePlan({ planId: selectedPlan.stripeId });
      if (response.status === 200) {
        dispatch(getUserData());
        dispatch(success(response?.data?.message));
        handleClose();
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
    } finally {
      setRequestLoader(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="downgradeModalWrapper"
        sx={{
          minHeight: planDetails ? "706px" : "400px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <img src={ModalClosePlansCIcon} alt="close" />
        </IconButton>
        <DialogContent className="downgradeModalContent" dividers>
          <>
            <img src={DowngradeCIcon} alt="downgrade" />
            <Box className="downgradeHeader">
              <Typography gutterBottom variant="h4">
                {MESSAGES.ACCOUNT.PLANS.DOWNGRADE.TITLE}
              </Typography>
              <Box className="package">
                <Typography gutterBottom>{selectedPlan.name}</Typography>
                <Typography gutterBottom>
                  {selectedPlan?.billMonthly === "Free" ||
                  selectedPlan?.amount === "0.00" ? (
                    "Free"
                  ) : (
                    <>
                      {balanceFormatter(selectedPlan.amount)}/
                      {selectedPlan.isYearly ? "yr" : "month"}
                    </>
                  )}
                </Typography>
              </Box>
              <Box className="description">
                <Typography gutterBottom>
                  You will not be billed today. Your plan will downgrade on{" "}
                  {planDetails?.planRenewsAt || ""} Starting on{" "}
                  {planDetails?.planRenewsAt || ""}
                  {selectedPlan?.billMonthly === "Free" ||
                  selectedPlan?.amount === "0.00"
                    ? " you will not be billed."
                    : " you will be billed for " +
                      balanceFormatter(selectedPlan.amount) +
                      (selectedPlan.isYearly
                        ? " every year."
                        : " every month.")}
                </Typography>
              </Box>
              <Box className="featureWrapper">
                <Typography>
                  {MESSAGES.ACCOUNT.PLANS.DOWNGRADE.HEADING}
                </Typography>
                <Grid container>
                  {Object.keys(currentPlanDetails?.features || {}).map(
                    (feature, index) => {
                      const currentFeature =
                        currentPlanDetails?.features?.[feature] || {};
                      const selectedFeature =
                        selectedPlan?.features?.[feature] || {};

                      const currentLimit = currentFeature.limit || 0;
                      const selectedLimit = selectedFeature.limit || 0;

                      if (
                        currentFeature.title &&
                        currentLimit !== 0 &&
                        selectedLimit !== -1 &&
                        currentLimit - selectedLimit !== 0
                      ) {
                        let limitText = "";
                        if (currentLimit === -1) {
                          limitText = "Unlimited";
                        } else if (
                          Math.abs(currentLimit - selectedLimit) !== 1
                        ) {
                          limitText =
                            Math.abs(currentLimit - selectedLimit) + " ";
                        }

                        return (
                          <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                            <Box className="featuresList">
                              <img src={CrossPlansCIcon} alt="cross" />
                              <Typography>
                                {limitText}
                                {currentFeature.title}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      }

                      return null;
                    }
                  )}
                </Grid>
              </Box>
              {planDetails?.planDifference?.length > 0 && (
                <Box className="downgradeInfo">
                  <Typography>
                    {MESSAGES.ACCOUNT.PLANS.DOWNGRADE.NOTE}
                    {planDetails?.planRenewsAt || ""}:
                  </Typography>
                  <ul>
                    {planDetails.planDifference.map((difference) => (
                      <li>{difference}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
            <Box className="downgradeModalBtns">
              <Button onClick={handleClose}>
                {MESSAGES.ACCOUNT.PLANS.DOWNGRADE.CANCEL_BUTTON}
              </Button>
              <Button onClick={onSubmit}>
                {requestLoader ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.PLANS.DOWNGRADE.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default DowngradeModal;
