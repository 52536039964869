import React, { useState, useEffect } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { fetchCustomFields } from "~/redux/actions/customFields-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants";

// Components
import CustomTable from "./CustomTable";
import CreateModal from "./CreateModal";
import EditCustomFieldModal from "./EditCustomFieldModal";
import DeleteModal from "./DeleteModal";

// MUI Components
import { Box, Typography, Button } from "@mui/material";

// Icons
import { InviteCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const CustomFields = () => {
  const [createModal, setcreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCustomField, setSelectedCustomField] = useState({
    id: null,
    value: null,
  });
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomFields(pagination));
  }, [pagination.page]);

  useEffect(() => {
    setPagination(DEFAULT_PAGINATION);
    dispatch(fetchCustomFields(DEFAULT_PAGINATION));
  }, []);

  useEffect(() => {
    dispatch(fetchCustomFields(pagination));
  }, []);

  return (
    <>
      <Box className="customWrapper">
        <Box className="modewrap">
          <Box
            className="headerWrap"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box className="UsersRow">
              <Typography variant="h4">
                {MESSAGES.SETTINGS.CUSTOM_FIELDS.TITLE}
              </Typography>
            </Box>
          </Box>
          <Box className="createBtn">
            <Button
              sx={{ "&:hover": { backgroundColor: "#ed5c2f" } }}
              onClick={() => setcreateModal(true)}
            >
              <img src={InviteCIcon} alt="addSign" />
              <Typography>{MESSAGES.SETTINGS.CUSTOM_FIELDS.CREATE}</Typography>
            </Button>
          </Box>
        </Box>
        <CustomTable
          setEditModal={setEditModal}
          setDeleteModal={setDeleteModal}
          setSelectedCustomField={setSelectedCustomField}
          updatePagination={setPagination}
          pagination={pagination}
        />
      </Box>
      <CreateModal
        openModal={createModal}
        handleCloseModal={() => setcreateModal(false)}
        pagination={pagination}
      />
      <DeleteModal
        openModal={deleteModal}
        handleCloseModal={() => setDeleteModal(false)}
        selectedCustomField={selectedCustomField}
        pagination={pagination}
      />
      <EditCustomFieldModal
        openModal={editModal}
        handleCloseModal={() => setEditModal(false)}
        selectedCustomField={selectedCustomField}
        pagination={pagination}
      />
    </>
  );
};

export default CustomFields;
