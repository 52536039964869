import React, { useEffect, useState } from "react";

// Hooks
import { useSelector, useDispatch } from "react-redux";
import useBroadcastChannel from "~/hooks/broadcastChannel";

// Actions
import { updateMode } from "~/redux/actions/mode-actions";
import { getUserData } from "~/redux/actions/auth-actions";
import { getAllPaymentMethods } from "~/redux/actions/stripe-actions";
import { CLEAR_CONTACTS_FILTERS } from "~/redux/actions/action-types";
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import Stripe from "~/components/Stripe/Stripe";
import PaymentMethod from "~/components/Account/Wallet/PaymentMethod";

// MUI Components
import { Box, Button } from "@mui/material";

// Styles
import "./styles.scss";


const Mode = () => {
  const dispatch = useDispatch();

  const currentPath = window?.location?.pathname;

  if (
    currentPath === "/admin/overview" ||
    currentPath === "/admin/orders" ||
    currentPath === "/admin/users" ||
    currentPath === "/admin/batches"
  ) {
    return;
  }

  const { broadcastMessage } = useBroadcastChannel('mode-update');

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode === true ? "Live" : state.userReducers.user.isLiveMode === false ? "Test" : null
  );

  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentMethodMonitor, setPaymentMethodMonitor] = useState(false);

  const paymentMethods = useSelector((state) => state.stripeReducers.methods);

  useEffect(() => {
    handleGetAllMethods();
  }, [])

  const handleGetAllMethods = () => {
    dispatch(getAllPaymentMethods());
  }

  const handleUpdate = (message) => {
    if (message.type === 'UPDATE_MODE') {
      dispatch(getUserData());
    }
  };

  const changeMode = async (mode) => {
    if (currentMode === mode) return;
    if (mode == "Live" && paymentMethods.length == 0) {
      setPaymentModal(true)
      setPaymentMethodMonitor(true)
      return
    }
    const payload = {
      mode: mode.toLowerCase(),
    };
    const request = await dispatch(updateMode(payload));
    if (request.status === 200) {
      dispatch(getUserData());
      dispatch({ type: CLEAR_CONTACTS_FILTERS });
      broadcastMessage({ type: 'UPDATE_MODE', data: mode });
    } else if(request?.response?.status === 422) {
      dispatch(failure(request?.response?.data?.message));
    } else {
      dispatch(failure(MESSAGES.GENERAL_ERROR));
    }
  };

  useBroadcastChannel('mode-update', handleUpdate);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  return (
    <>
    {
      currentMode &&
      <Box className="modebtnsWrapper">
        <Button
          className={currentMode === "Test" ? "active" : "inactive"}
          onClick={() => {
            changeMode("Test");
          }}
        >
          {MESSAGES.MODE.TEST_LABEL}
        </Button>
        <Button
          className={currentMode === "Live" ? "active" : "inactive"}
          onClick={() => {
            changeMode("Live");
          }}
        >
          {MESSAGES.MODE.LIVE_LABEL}
        </Button>
      </Box>
    }
      <Stripe>
        <PaymentMethod
          open={paymentModal}
          handleOpen={() => {
            setPaymentModal(true);
          }}
          handleLiveModeSwitch={async (status) => {
            if (paymentMethodMonitor && status) {
              setPaymentMethodMonitor(false)
              const payload = {
                mode: "live",
              };
              const request = await dispatch(updateMode(payload));
              if (request.status === 200) {
                dispatch(getUserData());
                dispatch({ type: CLEAR_CONTACTS_FILTERS });
              }
            }
          }}
          handleClose={() => {
            setPaymentModal(false);
          }}
        />
      </Stripe>
    </>
  );
};

export default Mode;
