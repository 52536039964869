import React, { useEffect, useState } from "react";

// Hooks
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure } from "~/redux/actions/snackbar-actions";

//Utils
import {
  DEFAULT_PAGE_SIZES,
  DEFAULT_PAGINATION,
} from "~/utils/constants";
import { createViewProofForOrder } from "~/utils/template-builder";
import { MESSAGES } from "~/utils/message";
import { stringLength } from "~/utils/helperFunctions";

// Components
import Loader from "~/components/General/Loader";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Select,
  MenuItem,
  Typography,
  Tooltip,
  CircularProgress,
  Stack,
} from "@mui/material";

// Icons
import { DisableCIcon, DispatchCIcon, NoContactCIcon } from "~/assets/images";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import "./styles.scss";

const OrdersTable = ({
  pagination,
  updatePagination,
  setOpenActionModal,
  setDispatchModal,
  setSelectedOrder,
  searchApplied
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [downloadingProof, setDownloadingProof] = useState({
    status: false,
    rowId: null,
  });

  const pageSizes = DEFAULT_PAGE_SIZES;

  const rows = useSelector((state) => state.adminOrdersReducers.orders);

  const rowsCount = useSelector((state) => state.adminOrdersReducers.rowsCount);
  const to = useSelector((state) => state.adminOrdersReducers.to);
  const from = useSelector((state) => state.adminOrdersReducers.from);
  const lastPage = useSelector(
    (state) => state.adminOrdersReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminOrdersReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.adminOrdersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const isLoading = useSelector((state) => state.adminOrdersReducers.loading);
  const dispatchLoadingOrderId = useSelector(
    (state) => state.adminOrdersReducers.dispatchLoadingOrderId
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const downloadViewProof = async (params) => {
    try {
      setDownloadingProof({
        status: true,
        id: params.row.orderId,
      });

      await dispatch(
        createViewProofForOrder(
          `Order-${params.row.orderId}`,
          {
            orderId: params.row.orderId,
          },
          "admin-order"
        )
      );
      setDownloadingProof({
        status: false,
        id: null,
      });
    } catch (error) {
      setDownloadingProof({
        status: false,
        id: null,
      });
      dispatch(failure(MESSAGES.VIEW_PROOF_ERROR));
    }
  };

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const productTypeStyles = {
    overflow: "auto !important",
    maxWidth: "130px",
    "@media (width < 1380px)": {
      maxWidth: "100%",
    },
  };

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.orderId || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => 
        params.row?.createdBy ? (
          <Tooltip title={`ID: ${params.row.userId}`}>
            <Box className="addressWrapper">
              <Link className="link" to={`/admin/users/${params.row.userId}`}>
                <Typography>{params.row.createdBy || " -"}</Typography>
              </Link>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper">
            <Typography>{" -"}</Typography>
          </Box>
        ),
    },
    {
      field: "batchId",
      headerName: "Batch ID",
      type: "number",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        params.row.batchId ? (
          <Box className="addressWrapper">
            <Link className="link" to={`/admin/batches/${params.row.batchId}`}>
              <Typography>{params.row.batchId || " -"}</Typography>
            </Link>
          </Box>
        ) : (
          <Box className="addressWrapper">
            <Typography>{" -"}</Typography>
          </Box>
        ),
    },
    {
      field: "source",
      headerName: "Source",
      width: 150,
      height: 500,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.source || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "type",
      headerName: "Product Type",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) =>
        stringLength(params.row.productType, 15) ? (
          <Tooltip title={params.row.productType}>
            <Box className="addressWrapper" sx={productTypeStyles}>
              <Typography>{params.row.productType || " -"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="addressWrapper" sx={{ overflow: "auto !important" }}>
            <Typography>{params.row.productType || " -"}</Typography>
          </Box>
        ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="valueWrapper">
          <Typography>{params.row.deliverableQuantity || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.revenue || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.scheduleDate || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "mailingDate",
      headerName: "Mailing Date",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Tooltip title={params.row.mailingDate}>
          <Box className="addressWrapper">
            <Typography>{params.row.mailingDate || " -"}</Typography>
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.status || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.paymentStatus || " -"}</Typography>
        </Box>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      flex: window.innerWidth >= 1380 ? 1 : 0,
      renderCell: (params) => (
        <Box className="actionsWrapper">
          {params.row.status !== "Canceled" && (
            <>
              <Tooltip title="Download Proof">
                {downloadingProof.status &&
                downloadingProof.id === params.row.orderId ? (
                  <CircularProgress
                    sx={{
                      color: "#ED5C2F",
                      width: "20px !important",
                      height: "20px !important",
                    }}
                  />
                ) : (
                  <SaveAltIcon sx={{
                    fill: "#545454",
                    stroke: "#ffffff",
                    strokeWidth: "0.6px",
                  }} onClick={() => downloadViewProof(params)}/>
                )}
              </Tooltip>
              {params.row.paymentStatus !== "Paid" && (
                <Tooltip title="Cancel Order">
                  <img
                    src={DisableCIcon}
                    alt="Cancel"
                    onClick={() => {
                      setSelectedOrder(params.row.orderId),
                        setOpenActionModal(true);
                    }}
                  />
                </Tooltip>
              )}
              {dispatchLoadingOrderId === params.row.orderId ? (
                <CircularProgress sx={{
                  color: "#ED5C2F",
                  width: "20px!important",
                  height: "20px!important",
                }}/>
              ) : params.row.paymentStatus === "Pending" &&
                params.row.status === "Scheduled" ? (
                <Tooltip title={params.row.dispatchedAt === null ? "Dispatch Now" : "Dispatched"}>
                  <img
                    height={14}
                    src={DispatchCIcon}
                    alt="icon"
                    onClick={params.row.dispatchedAt === null ? () => {setSelectedOrder(params.row.orderId) , setDispatchModal(true)} : null}
                    style={{
                      opacity: params.row.dispatchedAt === null ? "1" : "0.5",
                      cursor: params.row.dispatchedAt === null ? "pointer" : "not-allowed",
                    }}
                  />
                </Tooltip>
              ) : params.row.status === "Processing" || params.row.status === "Scheduled" && params.row.paymentStatus === "Paid" ? 
              <Tooltip title="Dispatched">
              <img
                height={14}
                src={DispatchCIcon}
                alt="icon"
                style={{
                  opacity: "0.5",
                  cursor: "not-allowed",
                }}
              />
            </Tooltip> : null} 
            </>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="adminUserTable">
        <div
          style={{
            marginTop: "14px",
            width: "100%",
          }}
          className="adminOrderstableWrapper"
        >
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            rowCount={rowsCount}
            pagination={pagination}
            paginationMode="server"
            hideFooterSelectedRowCount
            hideFooterPagination
            rowSelection={false}
            getRowId={(row) => row.key}
            disableColumnMenu={true}
            className="adminOrdersTable"
            slots={{
              noRowsOverlay: () => (
                <Stack className="noRowsTextContacts">
                  <img src={NoContactCIcon} alt="NoContact" />
                  {`${MESSAGES.NO_RESULTS_TEXT} ${!searchApplied ? "filters" : "search"}`}
                </Stack>
              ),
              noResultsOverlay: () => (
                <Stack className="noRowsTextContacts">
                  <img src={NoContactCIcon} alt="NoContact" />
                  {MESSAGES.NO_ROWS_TEXT}
                </Stack>
              ),
              loadingOverlay: Loader,
            }}
          />
          <Box className="paginationWrapper">
            <Pagination
              count={lastPage}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              page={currentPage}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIosNewIcon,
                    next: ArrowForwardIosIcon,
                  }}
                  {...item}
                />
              )}
            />
            <Select
              className={
                perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
              }
              value={perPage}
            >
              {pageSizes.map((pageSize) => {
                return (
                  <MenuItem
                    key={pageSize}
                    value={pageSize}
                    onClick={() => {
                      updatePagination({
                        page: 0,
                        pageSize: pageSize,
                      });
                    }}
                  >
                    {pageSize}
                  </MenuItem>
                );
              })}
            </Select>

            <Typography>
              Showing {rowsCount ? from : 0} to{" "}
              {rowsCount < to ? rowsCount : to} of {rowsCount} results
            </Typography>
          </Box>
        </div>
      </div>
    </>
  );
};

export default OrdersTable;
