import React from "react";

// Hooks
import { useNavigate } from "react-router-dom";

//Utils
import { MESSAGES } from "~/utils/message";

// Components
import CreateCategoryModal from "~/components/AdminPortal/Categories/CreateCategoryModal";

// MUI Components
import { Box, Button, Container, Typography } from "@mui/material";

// Assets
import { NoContactCIcon } from "~/assets/images";

// Icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// styles
import "./styles.scss";

const NoCategoriesScreen = (props) => {
  const {openModal, handleCloseModal, pagination, setcreateModal} = props;
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noContactWrapper">
        <img src={NoContactCIcon} alt="no-contacts" />
        <Typography>{MESSAGES.ADMIN_DASHBOARD.CATEGORIES.NO_CATEGORIES_SCREEN.TITLE}</Typography>
        <Box className="noContactBtns">
          <Button
            // onClick={() => {
            //   navigate("/contacts/create");
            // }}
            onClick={() => setcreateModal(true)}
          >
            <AddCircleOutlineIcon />
            {MESSAGES.ADMIN_DASHBOARD.CATEGORIES.NO_CATEGORIES_SCREEN.CREATE_CATEGORY_BUTTON}
          </Button>
        </Box>
      </Box>
      <CreateCategoryModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        pagination={pagination}
      />
    </Container>
  );
};

export default NoCategoriesScreen;
