import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// Mui Components
import { Input, InputLabel, Box } from "@mui/material";
import Typography from "@mui/material/Typography";

// Components
import MultiSelect from "~/components/Contacts/AdvanceFilters/MultiSelect";

// styles
import "./styles.scss";

const ModalInput = (props) => {
  const {
    label,
    type,
    isMultiSelect = false,
    options = [],
    value,
    setValue,
    name,
    realTimeValidation,
    formik,
  } = props;

  return (
    <Box
      className="modalInputWrapper"
      sx={{
        maxWidth: isMultiSelect ? "863px" : "416px",
        "@media (max-width:600px)": {
          maxWidth: "none",
        },
      }}
    >
      <InputLabel className="modalLabel">{label}</InputLabel>
      {!isMultiSelect ? (
        <Input
          type={type}
          placeholder={label}
          className={
            formik.errors[name] && formik.touched[name]
              ? "modalInputField invalid"
              : "modalInputField"
          }
          onBlur={realTimeValidation ? formik.handleBlur(name) : undefined}
          onKeyDown={realTimeValidation ? formik.handleBlur(name) : undefined}
          value={value}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
          }}
        />
      ) : (
         <MultiSelect
          options={options}
          selectedValue={value}
          setSelectedValue={setValue}
          isCheckboxEnabled={true}
          placeholder={MESSAGES.CONTACTS.CREATE.TAGS_PLACEHOLDER}
          component="contactTags"
        />
      )}
      {formik?.touched[name] && formik?.errors[name] ? (
        <Typography className="errorMessage">
          <sup>*</sup>
          {formik.errors[name]}
        </Typography>
      ) : null}
    </Box>
  );
};

export default ModalInput;
