import React, { useState } from "react";

// Hooks
import { useSelector } from "react-redux";

// Component
import EditNameModal from "./EditNameModal";
import EditAccountModal from "./EditAccountModal";
import ChangePasswordModal from "./ChangePasswordModal";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
} from "@mui/material";

// icons
import { EditAccountsCIcon } from "~/assets/images";

// styles
import "./styles.scss";

const Account = () => {
  const [editNameModal, setEditNameModal] = useState(false);
  const [editAccountModal, setEditAccountModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const user = useSelector((state) => state.userReducers?.user);

  return (
    <>
      <Box className="profileWrapper">
        <Grid container spacing={2} sx={{ padding: "0px 10px" }}>
          <Grid item xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2} sx={{ marginTop: "20px" }}>
              <Grid item xl={12} lg={12} md={6} sm={10} xs={10}>
                <Box className="profileRow">
                  <Typography variant="h4">
                    {MESSAGES.ACCOUNT.PROFILE.TITLE}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xxl={11}
                xl={11}
                lg={11}
                md={10}
                sm={10}
                xs={8}
                className="userinfoRow"
              >
                <Box>
                  <Typography variant="h4">
                    {MESSAGES.ACCOUNT.PROFILE.USER_HEADING}
                  </Typography>
                  <Grid
                    container
                    sx={{
                      columnGap: "158px",
                    }}
                  >
                    <Grid
                      item
                      lg={6}
                      md={5}
                      sm={12}
                      sx={{
                        width: "100% !important",
                        maxWidth: "290px !important",
                      }}
                    >
                      <Box className="infoWrapper">
                        <Typography>
                          {MESSAGES.ACCOUNT.PROFILE.NAME_LABEL}
                        </Typography>
                        <Typography>
                          {user?.fullName &&
                            (user.fullName.length > 35
                              ? `${user.fullName.slice(0, 30)}...`
                              : user.fullName)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={7}
                      sm={12}
                      sx={{
                        width: "100% !important",
                        maxWidth: "370px !important",
                      }}
                    >
                      <Box className="infoWrapper">
                        <Typography>
                          {MESSAGES.ACCOUNT.PROFILE.EMAIL_LABEL}
                        </Typography>
                        <Typography>{user?.email}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xxl={1} xl={1} lg={1} md={2} sm={2} xs={4}>
                <Box className="buttonContainer">
                  <Button onClick={() => setEditNameModal(true)}>
                    <img src={EditAccountsCIcon} alt="trash" />
                    <Typography>
                      {MESSAGES.ACCOUNT.PROFILE.EDIT_BTN_TEXT}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Divider className="profileDivider" />
            <Grid container spacing={2} sx={{ marginTop: "20px" }}>
              <Grid
                item
                xxl={11}
                xl={11}
                lg={11}
                md={10}
                sm={10}
                xs={8}
                className="userinfoRow"
                sx={{
                  paddingTop: "0 !important",
                }}
              >
                <Box>
                  <Typography variant="h4">
                    {MESSAGES.ACCOUNT.PROFILE.ACCOUNT_HEADING}
                  </Typography>
                </Box>
                <Grid
                  container
                  sx={{
                    columnGap: "158px",
                  }}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box className="infoWrapper noMargin">
                      <Typography>
                        {MESSAGES.ACCOUNT.PROFILE.COMPANY_LABEL}
                      </Typography>
                      <Typography>
                        {user?.companyName &&
                          (user.companyName.length > 55
                            ? `${user.companyName.slice(0, 50)}...`
                            : user.companyName)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    columnGap: "158px",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    md={5}
                    sm={12}
                    xs={12}
                    sx={{
                      width: "100% !important",
                      maxWidth: "290px !important",
                    }}
                  >
                    <Box className="infoWrapper noMargin">
                      <Typography>
                        {MESSAGES.ACCOUNT.PROFILE.ADDRESS_1_LABEL}
                      </Typography>
                      <Typography>
                        {user?.address1
                          ? user.address1.length > 55
                            ? `${user.address1.slice(0, 50)}...`
                            : user.address1
                          : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box className="infoWrapper noMargin">
                      <Typography>
                        {MESSAGES.ACCOUNT.PROFILE.ADDRESS_2_LABEL}
                      </Typography>
                      <Typography>
                        {user?.address2
                          ? user.address2.length > 55
                            ? `${user.address1.slice(0, 50)}...`
                            : user.address2
                          : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{
                    columnGap: "158px",
                  }}
                >
                  <Grid
                    item
                    lg={6}
                    md={5}
                    sm={12}
                    xs={12}
                    sx={{
                      width: "100% !important",
                      maxWidth: "290px !important",
                    }}
                  >
                    <Box className="infoWrapper noMargin">
                      <Typography>
                        {MESSAGES.ACCOUNT.PROFILE.CITY_LABEL}
                      </Typography>
                      <Typography>{user.city}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      width: "100% !important",
                      maxWidth: "290px !important",
                    }}
                  >
                    <Box className="infoWrapper noMargin">
                      <Typography>
                        {MESSAGES.ACCOUNT.PROFILE.STATE_LABEL}
                      </Typography>
                      <Typography>{user.state}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item lg={6} md={5} sm={12} xs={12}>
                    <Box className="infoWrapper noMargin">
                      <Typography>
                        {MESSAGES.ACCOUNT.PROFILE.ZIP_LABEL}
                      </Typography>
                      <Typography>{user.zip}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xxl={1}
                xl={1}
                lg={1}
                md={2}
                sm={2}
                xs={4}
                sx={{
                  paddingTop: "0 !important",
                }}
              >
                <Box className="buttonContainer">
                  <Button onClick={() => setEditAccountModal(true)}>
                    <img src={EditAccountsCIcon} alt="trash" />
                    <Typography>
                      {MESSAGES.ACCOUNT.PROFILE.EDIT_BTN_TEXT}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Divider className="profileDivider" />
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                sx={{ marginTop: "42px" }}
              >
                <Box className="buttonWrap">
                  <Button onClick={() => setChangePasswordModal(true)}>
                    {MESSAGES.ACCOUNT.PROFILE.CHANGE_PASSWORD_BTN_TEXT}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {editNameModal && (
        <EditNameModal
          openModal={editNameModal}
          handleCloseModal={() => setEditNameModal(false)}
          first={user.firstName}
          last={user.lastName}
        />
      )}
      {editAccountModal && (
        <EditAccountModal
          openModal={editAccountModal}
          handleCloseModal={() => setEditAccountModal(false)}
          user={user}
        />
      )}
      <ChangePasswordModal
        openModal={changePasswordModal}
        handleCloseModal={() => setChangePasswordModal(false)}
      />
    </>
  );
};

export default Account;
