//Actions
import {
    SET_CONTACTS_DATA, SET_CONTACTS_LOADER, SET_CONTACTS_LABELS,
    SET_CONTACTS_FILTERS, CLEAR_CONTACTS_FILTERS, LOGOUT
} from "~/redux/actions/action-types"

const initialState = {
    contacts: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalContactsInDB: null,
    loading: false,
    labels: [],
    applyFilters: false,
    filterSize: null,
    filters: {}
};

const contactsReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_CONTACTS_DATA:
            return {
                ...state, contacts: payload.rows, rowsCount: payload.count, totalContactsInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_CONTACTS_LOADER:
            return { ...state, loading: payload };
        case SET_CONTACTS_LABELS:
            return { ...state, labels: payload };
        case SET_CONTACTS_FILTERS:
            return { ...state, applyFilters: payload.applyFilters, filterSize: payload.filterSize, filters: payload.filters, };
        case CLEAR_CONTACTS_FILTERS:
            return { ...state, applyFilters: false, filterSize: null, filters: {} };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { contactsReducers };