import React from "react";

// Utils
import {
  balanceFormatter,
  numberWithComma,
} from "~/utils/helperFunctions";

// MUI Components
import { Box } from "@mui/material";

// Icons
import { OrderCIcon, RevenueCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

function StatsCard(props) {
  const { totalOrders, revenue } = props;
  return (
    <Box className="ordersInfo">
      <Box className="boxOuter">
        <Box className="box">
          <img src={OrderCIcon} alt="icon" />
          <Box>
            <span className="subtitle">No. of Orders</span>
            <p className="mainTxt">{numberWithComma(totalOrders)}</p>
          </Box>
        </Box>
        <Box className="box">
          <img src={RevenueCIcon} alt="icon" />
          <Box>
            <span className="subtitle">Total Revenue</span>
            <p className="mainTxt">{balanceFormatter(revenue)}</p>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default StatsCard;
