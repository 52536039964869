import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";
import {
  balanceFormatter,
  numberWithComma,
} from "~/utils/helperFunctions";

// Components
import Loader from "~/components/General/Loader";

// MUI Components
import { Box, Grid, Typography, Tooltip } from "@mui/material";

// Icons
import { ScheduleOrderCIcon } from "~/assets/images";

const CostDetails = (props) => {
  const { costDetails, loading } = props;

  return (
    <>
      <Grid
        item
        lg={4}
        md={12}
        sm={12}
        xs={12}
        sx={{ "@media (max-width: 1199px)": { marginTop: "11px" } }}
      >
        <Box
          className={
            loading ? "costLoaderWrapper estimatedWrapper" : "estimatedWrapper"
          }
        >
          {loading ? (
            <Loader />
          ) : (
            <>
              <Typography>
                {MESSAGES.ORDERS.CREATE.REVIEW.COST_HEADING}
                <Tooltip title={MESSAGES.ORDERS.CREATE.REVIEW.COST_TITLE}>
                  <span>
                    <img src={ScheduleOrderCIcon} alt="schedule" />
                  </span>
                </Tooltip>
              </Typography>
              <Typography>
                {balanceFormatter(costDetails?.cost || 0)}
              </Typography>
              <Typography>
                {numberWithComma(costDetails?.deliverableContacts) || 0}
                <span className="deliverable">
                  {costDetails?.deliverableContacts <= 1
                    ? MESSAGES.ORDERS.CREATE.REVIEW.DELIVERABLE_TITLE
                    : MESSAGES.ORDERS.CREATE.REVIEW.DELIVERABLES_TITLE}
                </span>{" "}
                <span className="x">x</span>{" "}
                {costDetails?.costGsv ? <span>(</span> : <></>}
                {balanceFormatter(costDetails?.perMailerCost) || 0}
                <span className="deliverable">
                  {MESSAGES.ORDERS.CREATE.REVIEW.MAILER_TITLE}
                </span>
                {costDetails?.costGsv ? (
                <>
                  &nbsp;<span className="x">+</span>&nbsp;
                  {balanceFormatter(costDetails.perMailerGsvCost )}
                  <span className="deliverable">&nbsp;GSV cost</span>)
                </>
              ) : null}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default CostDetails;
