import React from "react";

// Utils
import { EVENT_TYPES } from "~/utils/constants";
import { MESSAGES } from "~/utils/message";
import { dateFormat } from "~/utils/date-format";

// Components
import MultiSelect from "~/components/General/MultiSelect";
import Loader from "~/components/General/Loader";

// MUI Components
import { Box, Divider, Grid, Typography } from "@mui/material";

// styles
import "./styles.scss";

const EventLog = (props) => {
  const { data, eventType, setEventType, loading, mainLoader } = props;

  return (
    <>
      <Grid container>
        <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Box className="eventLogHead">
            <Typography variant="h6">
              {MESSAGES.CONTACTS.EVENTS_LOGS.TITLE}
            </Typography>
            <Box className="logSelect">
              <MultiSelect
                options={EVENT_TYPES}
                selectedValue={eventType}
                setSelectedValue={setEventType}
                multiple={false}
                placeHolderText="All"
                eventLog={true}
                component="eventLogDropdown"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {loading && !mainLoader && <Loader />}
      {!loading && !mainLoader && data.length === 0 ? (
        <Grid container mb={4}>
          <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Typography>No Events Logs Available</Typography>
          </Grid>
        </Grid>
      ) : (
        !loading &&
        !mainLoader &&
        data.map((log) => (
          <>
            <Grid container>
              <Grid item lg={2} md={2} sm={2} xs={2}></Grid>
              <Grid item lg={10} md={10} sm={10} xs={10}>
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container sx={{ alignItems: "center", gap: "20px" }}>
                      <Grid item lg={7} md={7} sm={7} xs={7}>
                        <Box className="logContent">
                          <Typography>
                            {log?.address1 + "," + log?.address2}
                            {log?.address2 ? ". " : " "}
                            <span>{log?.event}</span>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Box className="logDate">
                          <Typography>
                            {dateFormat(log?.updatedAt) || " -"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        borderColor: "#44424145",
                        marginLeft: "-30px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ))
      )}
    </>
  );
};

export default EventLog;
