import request from "~/utils/request";
import { SET_SOURCES, SET_SOURCES_LOADER } from "./action-types";

const fetchSources = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SET_SOURCES_LOADER, payload: true });
    const sources = await request.get("sources", payload);
    dispatch({ type: SET_SOURCES, payload: sources.data });
    return sources;
  } catch (error) {
    return error.response;
  } finally {
    dispatch({ type: SET_SOURCES_LOADER, payload: false });
  }
};

const updateSource = (payload) => async (dispatch) => {
  try {
    const update = await request.post(
      `api-keys/update-status`,
      {
        source: payload.source,
        status: payload.status,
      }
    );
    await dispatch(fetchSources());
    return update;
  } catch (error) {
    return error.response;
  }
};

export { fetchSources, updateSource };
