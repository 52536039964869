import request from "~/utils/request";

import { SET_ADMIN_DASHBOARD_CHART_DATA } from "./action-types"

const fetchAdminDashboardChart = (params) => async (dispatch) => {
    try {
        const chart = await request.get("admin/dashboard", params);
        dispatch({ type: SET_ADMIN_DASHBOARD_CHART_DATA, payload: chart.data.data });
        return chart;
    } catch (error) {
        return error;
    }
}

export { fetchAdminDashboardChart }