// Request util for api call
import request from "~/utils/request";

// Actions
import { SET_ADMIN_TEMPLATE_CATEGORIES_DATA, SET_ADMIN_TEMPLATE_CATEGORIES_LOADER } from "./action-types";

//Utils
import { DEFAULT_PAGINATION } from "~/utils/constants";

const fetchTemplateCategories = (payload) => async (dispatch) => {
    try {
        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }

        dispatch({ type: SET_ADMIN_TEMPLATE_CATEGORIES_LOADER, payload: true });
        const templateCategory = await request.get('admin/categories', queryPayload);
        dispatch({ type: SET_ADMIN_TEMPLATE_CATEGORIES_DATA, payload: templateCategory.data.data });
        return templateCategory;
    } catch (error) {
        return error.response;
    } finally {
        dispatch({ type: SET_ADMIN_TEMPLATE_CATEGORIES_LOADER, payload: false });
    }
}

const createTemplateCategory = (payload, pagination) => async (dispatch) => {
    try {
        const templateCategory = await request.post('admin/categories', payload);
        dispatch(fetchTemplateCategories(pagination));
        return templateCategory;
    } catch (error) {
        return error.response;
    }
}

const updateTemplateCategory = (id, payload, pagination) => async (dispatch) => {
    try {
        const updateTemplateCategory = await request.put(`/admin/categories/${id}`, payload);
        dispatch(fetchTemplateCategories(pagination));
        return updateTemplateCategory;
    } catch (error) {
        return error.response;
    }
}

const deleteTemplateCategory = (id, pagination) => async (dispatch) => {
    try {
        const deletedTemplateCategory = await request.delete(`/admin/categories/${id}`);
        dispatch(fetchTemplateCategories(pagination));
        return deletedTemplateCategory;
    } catch (error) {
        return error.response;
    }
}

const fetchCategoryDetails = (id, pagination) => async (dispatch) => {
    try {
        const categoryDetails = await request.get(`admin/categories/${id}`);
        dispatch(fetchTemplateCategories(pagination));
        return categoryDetails;
    } catch (error) {
        return error.response;
    }
}

const associateTemplateCategory = (payload, pagination) => async (dispatch) => {
    try {
        const associateTemplateCategory = await request.post(`admin/categories/associate-templates`, payload);
        dispatch(fetchTemplateCategories(pagination));
        return associateTemplateCategory;
    } catch (error) {
        return error.response;
    }
}

export { fetchTemplateCategories, createTemplateCategory, updateTemplateCategory, deleteTemplateCategory, associateTemplateCategory, fetchCategoryDetails };
