//Actions
import {
    SET_ADMIN_USERS_DATA, SET_ADMIN_USERS_LOADER, SET_ADMIN_USERS_FILTERS_LABELS,
    SET_ADMIN_USERS_FILTERS, CLEAR_ADMIN_USERS_FILTERS, LOGOUT
} from "~/redux/actions/action-types"

const initialState = {
    users: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalUsersInDB: null,
    filterslabels: [],
    loading: false,
    applyFilters: false,
    filterSize: null,
    filters: {}
};

const adminUsersReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ADMIN_USERS_DATA:
            return {
                ...state, users: payload.rows, rowsCount: payload.count, totalUsersInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_ADMIN_USERS_LOADER:
            return { ...state, loading: payload };
        case SET_ADMIN_USERS_FILTERS_LABELS:
            return { ...state, filterslabels: payload };
        case SET_ADMIN_USERS_FILTERS:
            return { ...state, applyFilters: payload.applyFilters, filterSize: payload.filterSize, filters: payload.filters, };
        case CLEAR_ADMIN_USERS_FILTERS:
            return { ...state, applyFilters: false, filterSize: null, filters: {} };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { adminUsersReducers };