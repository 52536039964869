import request from "~/utils/request";

import { SET_DASHBOARD_WIDGETS_DATA, SET_DASHBOARD_CHART_DATA, SET_LIMIT_MODAL, SET_DASHBOARD_WIDGETS_SPENDINGS } from "./action-types"

const fetchDashboardWidgets = () => async (dispatch) => {
    try {
        const dashboard = await request.get("dashboard/widgets");
        dispatch({ type: SET_DASHBOARD_WIDGETS_DATA, payload: dashboard.data.data });
        return dashboard;
    } catch (error) {
        return error;
    }
}

const fetchDashboardSpendings = (params) => async (dispatch) => {
    try {
        const dashboardSpendings = await request.get("dashboard/widgets", params);
        dispatch({ type: SET_DASHBOARD_WIDGETS_SPENDINGS, payload: dashboardSpendings.data.data });
        return dashboardSpendings;
    } catch (error) {
        return error;
    }
}

const fetchDashboardChart = (params) => async (dispatch) => {
    try {
        const chart = await request.get("/dashboard/charts", params);
        dispatch({ type: SET_DASHBOARD_CHART_DATA, payload: chart.data.data });
        return chart;
    } catch (error) {
        return error;
    }
}

const setLimitModal = (payload) => async (dispatch) => {
    dispatch({ type: SET_LIMIT_MODAL, payload })
}


export { fetchDashboardWidgets, fetchDashboardChart, setLimitModal, fetchDashboardSpendings }