import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import MultiSelect from "~/components/General/MultiSelect";

// Mui Components
import { Input, InputLabel, Box, Chip } from "@mui/material";
import Typography from "@mui/material/Typography";

// Icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Styles
import "./styles.scss";

const labelStyles = {
  fontFamily: "Inter !important",
  fontSize: "16px !important",
  fontWeight: "500 !important",
  lineHeight: "19px !important",
  letterSpacing: "0em !important",
  textAlign: "left !important",
};

const ModalInput = (props) => {
  const {
    label,
    type,
    isMultiSelect = false,
    options = [],
    value,
    setValue,
    name,
    realTimeValidation,
    formik,
    isCreate,
  } = props;

  const removeTag = (tag) => {
    setValue((selectedTags) =>
      selectedTags.filter((item) => {
        return item !== tag;
      })
    );
  };

  return (
    <Box
      className="createContactInput"
      sx={{
        width: "100%",
        maxWidth: isMultiSelect ? "99%" : "98%",
        "@media (max-width:600px)": {
          maxWidth: "none",
        },
      }}
    >
      <InputLabel className="modalLabel" sx={labelStyles}>
        {label}
      </InputLabel>
      {!isMultiSelect ? (
        <Input
          type={type}
          placeholder={label}
          className={
            formik.errors[name] && formik.touched[name]
              ? "modalInputField invalid"
              : "modalInputField"
          }
          sx={{
            width: isCreate && "100% !important",
            maxWidth: isCreate && "100% !important",
          }}
          onBlur={realTimeValidation ? formik.handleBlur(name) : undefined}
          onKeyDown={realTimeValidation ? formik.handleBlur(name) : undefined}
          value={value}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
          }}
        />
      ) : (
        <>
          <MultiSelect
            component={"tag-create"}
            options={options}
            selectedValue={value}
            setSelectedValue={setValue}
            placeHolderText={
              MESSAGES.CONTACTS.CREATE.CREATE_SEARCH_TAGS_PLACEHOLDER
            }
          />
          {value.length > 0 && (
            <Box className="selectedTagsWrapper">
              {value.map((selectedTag) => {
                return (
                  <Box className="tagContent" key={selectedTag}>
                    <Chip className="tagChip" label={selectedTag} />
                    <HighlightOffIcon onClick={() => removeTag(selectedTag)} />
                  </Box>
                );
              })}
            </Box>
          )}
        </>
      )}
      {formik?.touched[name] && formik?.errors[name] ? (
        <Typography className="errorMessage">
          <sup>*</sup>
          {formik.errors[name]}
        </Typography>
      ) : null}
    </Box>
  );
};

export default ModalInput;
