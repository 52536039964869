import React from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import request from "~/utils/request";

// Icons
import { VerifyLogoCIcon } from "~/assets/images";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// styles
import "./styles.scss";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UnverifiedEmail = (props) => {
  const { isOpen, handleCloseModal } = props;
  const dispatch = useDispatch();
  
  const reSendEmail = async () => {
    try {
      const reSendEmail = await request.get('/auth/user/resend-verification-email');
      handleCloseModal();
      dispatch(success(reSendEmail.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        className="verifyModalWrapper"
      >
        <DialogContent className="exportModalContent" dividers>
          <img src={VerifyLogoCIcon} alt="export" />
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.UNVERIFIED_EMAIL.TITLE}
            </Typography>
            <Typography gutterBottom>
              {MESSAGES.UNVERIFIED_EMAIL.HEADING}
            </Typography>
          </Box>
          <Box className="exportModalBtns">
            <Button onClick={reSendEmail}>
              {MESSAGES.UNVERIFIED_EMAIL.RESEND_BTN_TEXT}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default UnverifiedEmail;
