import React, { useEffect, useState } from "react";

// Hooks
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  fetchOrderContactDetails,
  orderBulkActions,
} from "~/redux/actions/orders-actions";
import { failure, success } from "~/redux/actions/snackbar-actions";
import { searchAndAdvanceChange } from "~/redux/actions/orders-actions";

// Utils
import { doesItFallInCancellationPeriod } from "~/utils/date-format";
import { MESSAGES } from "~/utils/message";

// Components
import FilterModal from "./FilterModal";
import ContactListing from "./Table";
import ConfirmDialog from "~/components/Orders/ConfirmDialog/ConfirmDialog";

// MUI Components
import {
  Container,
  Grid,
  Box,
  Input,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";

// Icons
import {
  InActiveFilterCIcon,
  TemplateFilterCIcon,
  TemplateSearchOneCIcon,
  DeleteContactsCIcon,
  CancelOrderCIcon,
} from "~/assets/images";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Styles
import "./styles.scss";

/**
 * Renders a search input, a filter button, a create button, and a template listing component.
 * Handles navigation to the template builder page and manages the state of the search input.
 *
 * @returns {JSX.Element} The rendered Templates component.
 */
const OrderContacts = () => {
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const dispatch = useDispatch();
  const [isOpenAdvanceFilter, setIsOpenAdvanceFilter] = useState(false);
  const [searchApplied, setSearchApplied] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    loading: false,
    currentAction: null,
    params: null,
  });

  const { id } = useParams();

  const search = useSelector(
    (state) => state.ordersReducers.detailContacts.search
  );
  const contactsPagination = useSelector(
    (state) => state.ordersReducers.detailContacts.contactsPagination
  );

  const filters = useSelector(
    (state) => state.ordersReducers.detailContacts.filters
  );

  const filterSize = Object.keys(filters).length;

  const selectedOrder = useSelector(
    (state) => state.ordersReducers.detailOverview.stepper
  );

  const getAllContacts = (payload) => {
    dispatch(
      fetchOrderContactDetails({
        id,
        pagination: contactsPagination,
        search,
        ...payload,
      })
    );
  };

  const handleSearch = () => {
    if (search) {
      setSearchApplied(true);
      filterSize > 0
        ? getAllContacts({ filters, applyFilters: true })
        : getAllContacts();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && search) {
      event.preventDefault();
      setSearchApplied(true);
      filterSize > 0
        ? getAllContacts({ filters, applyFilters: true })
        : getAllContacts();
    }
  };

  const clearAdvanceFilter = () => {
    dispatch(searchAndAdvanceChange("filters", {}));
    getAllContacts();
  };

  const handleCloseDialog = () => {
    setDialog({
      open: false,
      message: "",
      loading: false,
      params: null,
      currentAction: null,
    });
  };

  const handleCancelOrder = async () => {
    setDialog({
      open: true,
      message:
        selectedRows[0] === -1 || selectedRows.length > 1
          ? MESSAGES.ORDERS.DETAIL.CONTACTS.REMOVE.MODAL.REMOVE_CONTACT
              .REMOVE_CONTACTS
          : MESSAGES.ORDERS.DETAIL.CONTACTS.REMOVE.MODAL.REMOVE_CONTACT
              .REMOVE_SINGLE_CONTACTS,
      loading: false,
      currentAction: "remove",
      successButtonName: "Yes",
      subMessage:
        MESSAGES.ORDERS.DETAIL.CONTACTS.REMOVE.MODAL.REMOVE_CONTACT.NOTE,
      heading:
        selectedRows[0] === -1 || selectedRows.length > 1
          ? MESSAGES.ORDERS.DETAIL.CONTACTS.REMOVE.MODAL.REMOVE_CONTACT
              .MULTI_REMOVE_HEADING
          : MESSAGES.ORDERS.DETAIL.CONTACTS.REMOVE.MODAL.REMOVE_CONTACT
              .SINGLE_REMOVE_HEADING,
      icon: CancelOrderCIcon,
    });
  };

  const deleteContacts = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "remove order items",
        orderId: id,
        search: search.length ? search : null,
        applyFilters: filterSize > 0 ? true : false,
        filters: filterSize > 0 ? filters : null,
      };
      const response = await orderBulkActions(payload, true);
      getAllContacts();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
    } finally {
      setLoading(false);
      handleCloseDialog();
    }
  };

  useEffect(() => {
    if (!search) {
      setSearchApplied(false);
      filterSize > 0
        ? getAllContacts({ filters, applyFilters: true })
        : getAllContacts();
    }
  }, [search]);

  return (
    <Box className="order_contact_wrapper">
      <Container maxWidth={maxWidth}>
        {isOpenAdvanceFilter && (
          <FilterModal
            open={isOpenAdvanceFilter}
            handleClose={() => setIsOpenAdvanceFilter(false)}
            search={search}
            handleSearch={() => {}}
          />
        )}
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12} className="searchBtnWrapper">
            <Box className="searchWrapper">
              {/* Search Input */}
              <Input
                className={`searchInput ${
                  searchApplied ? "searchApplied" : ""
                }`}
                type="text"
                autoComplete="off"
                value={search}
                name="search"
                onKeyDown={handleKeyDown}
                onChange={(e) =>
                  dispatch(
                    searchAndAdvanceChange(
                      e.target.name,
                      e.target.value.trimStart()
                    )
                  )
                }
                placeholder="Search by name, company, or address"
                endAdornment={
                  <InputAdornment
                    position="end"
                    className="searchInputAdornment"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      className="searchIcon"
                      onClick={handleSearch}
                    >
                      <img src={TemplateSearchOneCIcon} alt="search" />
                    </IconButton>
                    {searchApplied && (
                      <HighlightOffIcon
                        onClick={() => {
                          dispatch(searchAndAdvanceChange("search", ""));
                        }}
                        className="clearSerach"
                      />
                    )}
                  </InputAdornment>
                }
              />

              {/* Filter Button */}
              <Box className="filterBtnWrapper">
                <img
                  onClick={() => {
                    setIsOpenAdvanceFilter(true);
                  }}
                  className={
                    filterSize > 0
                      ? "filterImage activeFilter"
                      : "filterImage noFilter"
                  }
                  src={filterSize > 0 ? TemplateFilterCIcon : InActiveFilterCIcon}
                  alt="Filter"
                />
                {filterSize > 0 && (
                  <Typography className="filtersCounter">
                    {filterSize}
                  </Typography>
                )}
              </Box>
              {filterSize > 0 && (
                <Button onClick={clearAdvanceFilter} className="clearFilterBtn">
                  {MESSAGES.ORDERS.DETAIL.CONTACTS.CLEAR_FILTERS}
                </Button>
              )}
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="removeBtnWrapper"
            sx={{
              marginTop: { md: "0px", xs: "25px" },
            }}
          >
            {doesItFallInCancellationPeriod(
              selectedOrder?.campaign?.deliveryDate
            ) &&
              (selectedOrder?.status == "On Hold" ||
                selectedOrder?.status == "Scheduled") &&
              selectedRows.length > 0 && (
                <Button onClick={() => handleCancelOrder()}>
                  <img src={DeleteContactsCIcon} alt="delete" />
                  {MESSAGES.ORDERS.DETAIL.CONTACTS.REMOVE.CONTACT}
                </Button>
              )}
          </Grid>
        </Grid>

        {/* Contact Listing Component */}
        <ContactListing
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />

        {dialog.open && (
          <ConfirmDialog
            dialog={dialog}
            open={dialog.open}
            loading={loading}
            handleClose={handleCloseDialog}
            handleOk={deleteContacts}
          />
        )}
      </Container>
    </Box>
  );
};

export default OrderContacts;
