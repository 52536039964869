import React from "react";

// MUI Components
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";

// Styles
import "./styles.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = (props) => {
  const {
    options,
    selectedValue,
    setSelectedValue,
    component,
    productType,
    multiple = true,
    placeHolderText = "",
    noOptionText,
    error,
  } = props;
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (component === "tag-create") {
      const selectedOption = options.find((tag) => {
        return tag.title == value[value.length - 1];
      });
      if (selectedOption.func) {
        selectedOption.func();
        return;
      }
    }
    setSelectedValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const slicedString = (title) => {
    if (title?.length > 50) {
      const slicedTitle = title?.slice(0, 40);
      const lastSpaceIndex = slicedTitle?.lastIndexOf(" ");
      return lastSpaceIndex !== -1
        ? `${slicedTitle?.slice(0, lastSpaceIndex)}...`
        : `${slicedTitle}...`;
    } else {
      return title;
    }
  };

  // className for Select based on Props
  const selectClass =
    component === "eventLogDropdown"
      ? "eventDateSelect"
      : component === "signup"
      ? `${error ? "selectError" : null} signupSelect`
      : component === "API Key"
      ? `${error ? "apiKeySelectError" : null} statuSelect`
      : "statuSelect";

  return (
    <FormControl
      className={`checkboxSelectWrapper ${
        component === "envelopType" && "custom-envelop-type"
      }`}
    >
      <Select
        id="demo-multiple-checkbox"
        multiple={multiple}
        displayEmpty
        value={selectedValue}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return (
              <Typography
                style={{ marginBottom: "0px" }}
                sx={{ opacity: "0.5" }}
              >
                {placeHolderText}
              </Typography>
            );
          }
          return selected?.join(", ");
        }}
        MenuProps={{
          ...MenuProps,
          classes: {
            paper:
              component === "overviewdateDropDown"
                ? "custom-menu-paper"
                : component === "eventLogDropdown"
                ? "custom-eventLog-paper"
                : "",
          },
          MenuListProps: {
            onClick: handleMenuOpen,
            onFocus: handleMenuOpen,
            onBlur: handleMenuClose,
          },
        }}
        IconComponent={() => (
          <KeyboardArrowDownIcon
            sx={{
              color: menuOpen ? "#ED5C2F" : "inherit",
              position: "absolute",
              right: "13px",
            }}
          />
        )}
        className={selectClass}
        sx={{
          color: component === "overviewdateDropDown" && "#838383",
          "&.statuSelect .MuiSelect-select": {
            maxWidth:
              component === "editwebhook" || "Envelope Type" || "API Key"
                ? "100% !important"
                : "500px !important",
            padding: productType
              ? "14px 35px 14px 22px !important"
              : "14px 35px 14px 22px !important",
          },
        }}
      >
        {options?.length > 0 ? (
          options?.map(({ id, title, icon }) => (
            <MenuItem key={id} value={title}>
              {multiple &&
                (icon ? (
                  <div>
                    <img src={icon} alt="plus" className="addNew" />
                  </div>
                ) : (
                  <Checkbox checked={selectedValue?.indexOf(title) > -1} />
                ))}
              <ListItemText primary={slicedString(title)} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled className="noOptionsText">
            <Typography>{noOptionText}</Typography>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
