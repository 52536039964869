import React, { useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { createCustomField } from "~/redux/actions/customFields-actions";
import { success, failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CreateModal = (props) => {
  const { openModal, handleCloseModal, pagination } = props;

  const [loading, setLoading] = useState(false);

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(MESSAGES.SETTINGS.CUSTOM_FIELDS.CUSTOM_FIELD_NAME_REQUIRED)
      .min(1, MESSAGES.SETTINGS.CUSTOM_FIELDS.CUSTOM_FIELD_NAME_REQUIRED)
      .max(25, MESSAGES.SETTINGS.CUSTOM_FIELDS.CUSTOM_FIELD_NAME_LIMIT),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
  });

  const createNewCustomField = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const payload = {
        customFieldName: formik.values.name,
      };
      const response = await dispatch(createCustomField(payload, pagination));
      if (
        response.data.message ==
        MESSAGES.SETTINGS.CUSTOM_FIELDS.RESPONSE_ALREADY_EXIST
      ) {
        formik.setErrors({
          name: MESSAGES.SETTINGS.CUSTOM_FIELDS.FIELD_EXISTS,
        });
      } else {
        dispatch(success(response.data.message));
        onClose();
      }
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="CreateModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.SETTINGS.CUSTOM_FIELDS?.CREATE_CUSTOM_FIELD}
            </Typography>
          </Box>
          <form className="updateFormWrapper" onSubmit={createNewCustomField}>
            <Box
              sx={{
                maxWidth: "564px",
                marginInline: "auto",
                textAlign: "start",
                marginTop: "16px",
              }}
            >
              <InputLabel htmlFor="Name" className="inputLabel">
                {MESSAGES.SETTINGS.CUSTOM_FIELDS?.NAME}
              </InputLabel>
              <TextField
                placeholder={MESSAGES.SETTINGS.CUSTOM_FIELDS?.PLACEHOLDER}
                fullWidth
                name="name"
                className={
                  formik.errors.name && formik.touched.name
                    ? "inputField invalid"
                    : "inputField"
                }
                sx={{
                  "& input::placeholder": {
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "normal",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                  },
                  outline: "none",
                  "&:focus": {
                    outline: "none",
                  },
                }}
                onChange={formik.handleChange}
                value={formik.values.name.trimStart()}
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
              />
              {formik.errors.name ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.name}
                </Typography>
              ) : null}
            </Box>
            <Box className="editNameModalBtns">
              <Button className="btnCancel" onClick={onClose}>
                {MESSAGES.ACCOUNT.CANCEL_BUTTON}
              </Button>
              <Button
                className="btnSave"
                type="submit"
                onClick={() => validateForm(formik)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.SETTINGS.CUSTOM_FIELDS.CREATE
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default CreateModal;
