import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// Icons
import { ModalCrossCIcon, TemplateTrashCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

/**
 * Renders a modal dialog for deleting a template.
 *
 * @param {function} handleDelete - Callback function to handle the delete action.
 * @param {function} handleClose - Callback function to handle the close action.
 * @param {boolean} open - Boolean value indicating whether the modal is open or not.
 * @returns {JSX.Element} The delete template modal component.
 */
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DeleteTemplateModal = ({ handleDelete, handleClose, open = false }) => {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="deleteModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent className="deleteModalContent">
        <Box className="trashIconWrapper">
          <img src={TemplateTrashCIcon} alt="trash" />
          <Typography>{MESSAGES.TEMPLATE.DELETE.TITLE}</Typography>
        </Box>
        <Box className="deleteText">
          <Typography>{MESSAGES.TEMPLATE.DELETE.HEADING}</Typography>
          <Typography>{MESSAGES.TEMPLATE.DELETE.PARAGRAPH}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button autoFocus onClick={handleClose}>
          No
        </Button>
        <Button autoFocus onClick={handleDelete}>
          Yes
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DeleteTemplateModal;
