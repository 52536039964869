import React from "react";

//Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import { Box, Container, Typography } from "@mui/material";

// Assets
import { NoContactCIcon } from "~/assets/images";


// styles
import "./styles.scss";

const NoAdminUsersScreen = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noContactWrapper">
        <img src={NoContactCIcon} alt="no-contacts" />
        <Typography>{MESSAGES.ADMIN_DASHBOARD.USERS.NO_USERS_SCREEN.TITLE}</Typography>
      </Box>
    </Container>
  );
};

export default NoAdminUsersScreen;
