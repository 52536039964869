import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { impersonateUser } from "~/redux/actions/admin-users-actions.js";
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message.js";

// Mui components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { ModalCrossCIcon, User2CIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ImpersonateModal = (props) => {
  const { selectedUser, handleClose, open } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const impersonate = async () => {
    try {
      setLoading(true);
      const request = await dispatch(impersonateUser(selectedUser?.id));
      if (request.status !== 200) {
        dispatch(failure(request?.response?.data?.message));
      }
    } catch (error) {
      dispatch(
        failure(error?.response?.data?.message || MESSAGES.GENERAL_ERROR)
      );
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="impersonateModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        <img height={77} src={User2CIcon} alt="icon" />
        <Typography>Impersonation</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent className="filterModalContent">
        <Box className="wrapper">
          <Typography>
            {`${MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.IMPERSONATE_MODAL.HEADING} ${selectedUser?.name}?`}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button onClick={handleClose}>
          {
            MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.IMPERSONATE_MODAL
              .CANCEL_BUTTON
          }
        </Button>
        <Button onClick={impersonate}>
          {loading ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "25px !important",
                height: "25px !important",
              }}
            />
          ) : (
            MESSAGES.ADMIN_DASHBOARD.USERS.IMPERSONATION.IMPERSONATE_MODAL
              .SUBMIT_BUTTON
          )}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ImpersonateModal;
