import React, { useEffect, useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";
import { updateTag } from "~/redux/actions/tags-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EditTagModal = (props) => {
  const { openModal, handleCloseModal, selectedTag } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(35, MESSAGES.SETTINGS.TAGS.EDIT.TAG_LIMIT)
      .required(MESSAGES.SETTINGS.TAGS.EDIT.TAG_REQUIRED),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
  });

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
  };

  const updateExistingTag = async (event) => {
    try {
      event.preventDefault();
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const payload = {
        title: formik.values.name.trim(),
      };
      const response = await dispatch(updateTag(selectedTag.id, payload));
      dispatch(success(response.data.message));
      onClose();
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    formik.values.name = selectedTag.title;
  }, [selectedTag]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="editTagModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.SETTINGS.TAGS.EDIT.TITLE}
            </Typography>
          </Box>
          <form className="updateFormWrapper" onSubmit={updateExistingTag}>
            <Box className="editNameInputsWrapper">
              <InputLabel htmlFor="first-name" className="inputLabel">
                {MESSAGES.SETTINGS.TAGS.EDIT.TAG_LABEL}
              </InputLabel>
              <TextField
                placeholder={MESSAGES.SETTINGS.TAGS.EDIT.TAG_PLACEHOLDER}
                fullWidth
                name="name"
                autoComplete="off"
                onChange={formik.handleChange}
                className={
                  formik.errors.name && formik.touched.name
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={realTimeValidation ? formik.handleBlur : undefined}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.name}
                </Typography>
              ) : null}
            </Box>
            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.SETTINGS.TAGS.CANCEL_BUTTON}
              </Button>
              <Button
                className="btnSave"
                type="submit"
                onClick={() => validateForm(formik)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.SETTINGS.TAGS.EDIT.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default EditTagModal;
