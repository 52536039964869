import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getAllTransactions } from "~/redux/actions/stripe-actions";

// Utils
import { dateFormat } from "~/utils/date-format";
import { balanceFormatter } from "~/utils/helperFunctions";
import { TRANSACTION_STATUS } from "~/utils/constants";
import { MESSAGES } from "~/utils/message";

// Components
import Loader from "~/components/General/Loader";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Stack,
} from "@mui/material";

// Icons
import { NoContactCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Styles
import "./styles.scss";

const TransactionListing = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state) => state.stripeReducers?.transactions
  );

  const isLoading = useSelector((state) => state.stripeReducers?.transactions?.loading);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Typography>{dateFormat(params.row.createdAt, true)}</Typography>
      ),
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => <Typography>{params.row.id}</Typography>,
    },
    {
      field: "description",
      headerName: "Description",
      type: "number",
      width: 450,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => <Typography>{params.row.remarks}</Typography>,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      height: 550,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => {
        const balance = balanceFormatter(params.row.amount);
        return (
          <Typography>
            <span className="icon-color">
              {TRANSACTION_STATUS[params.row.type]}
            </span>{" "}
            {balance}
          </Typography>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => {
        const balance = balanceFormatter(params.row.meta.newBalance);
        return <Typography>{balance}</Typography>;
      },
    },
  ];

  useEffect(() => {
    handleGetTransactions(transactions.currentPage, transactions.perPage);
  }, []);
  const handleGetTransactions = (page, perPage) => {
    dispatch(getAllTransactions(page, perPage));
  };

  const handleChangePage = (event, page) => {
    handleGetTransactions(page, transactions.perPage);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="fundsTableWrapper"
    >
      <DataGrid
        rows={transactions.rows}
        columns={columns}
        pageSizeOptions={[20, 50, 100]}
        paginationMode="server"
        hideFooterSelectedRowCount
        loading={isLoading}
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="fundsTableGrid"
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.ACCOUNT.WALLET.STRIPE.NO_TRANSACTION}
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="fundsPaginationWrapper">
        <Pagination
          count={Math.ceil(transactions.count / transactions.perPage)}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={transactions.currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />

        <Typography>
          Showing {transactions.from} to {transactions.to} of{" "}
          {transactions.count} results
        </Typography>
      </Box>
    </div>
  );
};

export default TransactionListing;
