import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// Mui Components
import { Box, InputLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// styles
import "../styles.scss";

const SelectContact = (props) => {
  const { value, setValue } = props;
  return (
    <Box className="templateSelectWrapper">
      <InputLabel className="selectLabel">
        {MESSAGES.ORDERS.CREATE.CONTACTS.CONTACT_LABEL}
      </InputLabel>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          className="contactSelectors"
          value={value}
          onChange={() => setValue(event.target.value)}
        >
          <FormControlLabel
            value="Single"
            control={<Radio />}
            label={MESSAGES.ORDERS.CREATE.CONTACTS.SINGLE}
            className="contactSelectorText"
          />
          <FormControlLabel
            value="Multiple"
            control={<Radio />}
            label={MESSAGES.ORDERS.CREATE.CONTACTS.MULTIPLE}
            className="contactSelectorText"
          />
          <FormControlLabel
            value="Upload"
            control={<Radio />}
            label={MESSAGES.ORDERS.CREATE.CONTACTS.UPLOAD}
            className="contactSelectorText"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default SelectContact;
