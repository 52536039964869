import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { isAdmin } from "~/hooks/permissions";

// Actions
import { getTeamMembersData } from "~/redux/actions/user-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants";

// Component
import InviteModal from "./InviteModal";
import UserTable from "./UserTable";

// MUI Components
import { Box, Typography, Button } from "@mui/material";

// Icons
import { InviteCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const User = () => {
  const [inviteModal, setinviteModal] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const dispatch = useDispatch();

  useEffect(() => {
    let payload = {
      page: pagination.page,
      pageSize: 10,
    };
    dispatch(getTeamMembersData(payload));
  }, [pagination.page]);

  return (
    <>
    <Box className="userWrapper">
      <Box
        className="headerWrap"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box className="UsersRow">
          <Typography variant="h4">{MESSAGES.ACCOUNT.USERS.TITLE}</Typography>
        </Box>
        {isAdmin() && (
          <Box className="inviteBtn">
            <Button
              sx={{ "&:hover": { backgroundColor: "#ed5c2f" } }}
              onClick={() => setinviteModal(true)}
            >
              <img src={InviteCIcon} alt="trash" />
              <Typography>{MESSAGES.ACCOUNT.USERS.INVITE_BTN_TEXT}</Typography>
            </Button>
          </Box>
        )}
      </Box>
      <UserTable pagination={pagination} updatePagination={setPagination} />
      </Box>
      <InviteModal
        openModal={inviteModal}
        handleCloseModal={() => setinviteModal(false)}
      />
    </>
  );
};

export default User;
