//Actions
import {
    SET_ORDERS_DATA, SET_ORDERS_LOADER, SET_ORDERS_FILTERS_LABELS,
    SET_ORDERS_FILTERS, CLEAR_ORDERS_FILTERS, LOGOUT, SET_ORDERS_DETAIL_CONTACTS_DATA, SET_ORDERS_DETAIL_OVERVIEW_DETAIL, SET_ORDER_DETAIL_LOADER, CONTACTS_SEARCH,SET_ORDER_DETAIL_CONTACTS_LOADER
} from "~/redux/actions/action-types"

const initialState = {
    orders: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalOrdersInDB: null,
    filterslabels: [],
    loading: false,
    applyFilters: false,
    filterSize: null,
    filters: {},

    //Detail - Overview
    detailOverview: {
        loading: true,
        stepper: {
          
        },
        overview: {
        
        },
       
        analytics: {
        },
    },
    
   // Detail - Contacts 
    detailContacts: {
        list: [

        ],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalOrdersInDB: null,
    loading: false,
    applyFilters: false,
    filterSize: null,
    filters: {},
    contactsPagination: { page: 0, pageSize: 20, loading: false },
    search: ''
    
    }
};

const ordersReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ORDERS_DATA:
            return {
                ...state, orders: payload.rows, rowsCount: payload.count, totalOrdersInDB: payload.totalRecordsInDB,
                to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage
            };
        case SET_ORDERS_DETAIL_CONTACTS_DATA:
            return {
                ...state, detailContacts: { ...state.detailContacts, list: payload.rows, rowsCount: payload.count, totalOrdersInDB: payload.totalRecordsInDB, to: payload.to, from: payload.from, lastPage: payload.lastPage, currentPage: payload.currentPage, perPage: payload.perPage }
            }
        case CONTACTS_SEARCH:
                return {
                  ...state,
                  detailContacts: {...state.detailContacts, [payload.name]: payload.value}
                };
        case SET_ORDERS_DETAIL_OVERVIEW_DETAIL:
                return { ...state, detailOverview: {...state.detailOverview, stepper: payload.stepper, overview: payload.overview, analytics: payload.analytics} }
        // case SET_ORDERS_DETAIL_OVERVIEW_DETAIL:
        //             return { ...state, detailOverview: {...state.detailOverview, stepper: payload.stepper, overview: payload.overview, analytics: {...payload.analytics,"orderItemStatuses": {
        //                 "Mailed": 100,
        //                 "In Transit": 30,
        //                 "Delivered": 33,
        //                 "Re-Routed": 27,
        //                 "Returned to Sender": 10
        //             }, geoChart: {...payload.analytics.geoChart,     chartData: {...payload.analytics.geoChart.chartData , "FL": {
        //                 "name": "Florida",
        //                 "count": 6
        //             },
        //             "TX": {
        //                 "name": "Texas",
        //                 "count": 2
        //             },}, }}} }
                    
        case SET_ORDERS_LOADER:
            return { ...state, loading: payload };
        case SET_ORDER_DETAIL_CONTACTS_LOADER:
            return { ...state, detailContacts: {...state.detailContacts, loading: payload} };
        case SET_ORDER_DETAIL_LOADER:
            return {...state, detailOverview:{...state.detailOverview, loading: payload}}
        case SET_ORDERS_FILTERS_LABELS:
            return { ...state, filterslabels: payload };
        case SET_ORDERS_FILTERS:
            return { ...state, applyFilters: payload.applyFilters, filterSize: payload.filterSize, filters: payload.filters, };
        case CLEAR_ORDERS_FILTERS:
            return { ...state, applyFilters: false, filterSize: null, filters: {} };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export { ordersReducers };