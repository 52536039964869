import React, { useEffect, useState } from "react";

// Third Party Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  searchAndAdvanceChange,
  fetchOrderContactDetails,
} from "~/redux/actions/orders-actions.js";
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message.js";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Grid, InputLabel } from "@mui/material";

// Components
import MultiSelect from "~/components/General/MultiSelect";

// Icons
import { ModalCrossCIcon } from "~/assets/images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const address = [
  {
    id: 1,
    title: "Verified",
  },
  {
    id: 2,
    title: "Corrected",
  },
  {
    id: 3,
    title: "Failed",
  },
];
const status = [
  {
    id: 1,
    title: "Not Mailed",
  },
  {
    id: 2,
    title: "Mailed",
  },
  {
    id: 3,
    title: "Scanned",
  },
  {
    id: 4,
    title: "Not Scanned",
  },
  {
    id: 5,
    title: "Early",
  },
  {
    id: 6,
    title: "On Time",
  },
  {
    id: 7,
    title: "Late",
  },
  {
    id: 8,
    title: "En Route",
  },
  {
    id: 9,
    title: "Delivered",
  },
  {
    id: 10,
    title: "Re-Routed",
  },
  {
    id: 11,
    title: "Returned to Sender",
  },
];

const FilterModal = ({ handleClose, open, search }) => {
  const [addressStatus, setAddressStatus] = useState([]);
  const [mailedStatus, setMailedStatus] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);

  const contactsPagination = useSelector(
    (state) => state.ordersReducers.detailContacts.contactsPagination
  );

  const appliedFilters = useSelector(
    (state) => state.ordersReducers.detailContacts.filters
  );

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleSearch = () => {
    if (
      addressStatus.length === 0 &&
      mailedStatus.length === 0 &&
      startDate === null &&
      endDate === null
    ) {
      dispatch(
        failure(MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.FILTER_REQUIRED)
      );
      return;
    }
    let filters = {};
    addressStatus.length > 0 && (filters.addressStatus = addressStatus);
    mailedStatus.length > 0 && (filters.mailedStatus = mailedStatus);
    startDate !== null &&
      endDate !== null &&
      (filters.deliveredDate = { startDate, endDate });

    dispatch(searchAndAdvanceChange("filters", filters));
    dispatch(
      fetchOrderContactDetails({
        id,
        filters: filters,
        applyFilters: true,
        search,
      })
    );
    handleClose();
  };

  const handleClear = () => {
    dispatch(searchAndAdvanceChange("filters", {}));
    dispatch(fetchOrderContactDetails({ id, pagination: contactsPagination }));
    handleClose();
  };

  useEffect(() => {
    if (Object.keys(appliedFilters).length > 0) {
      setAddressStatus(appliedFilters.addressStatus || []);
      setMailedStatus(appliedFilters.mailedStatus || []);
      appliedFilters.deliveredDate &&
        setDateRange([
          appliedFilters.deliveredDate.startDate,
          appliedFilters.deliveredDate.endDate,
        ]);
    }
  }, []);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="contactfilterModalWrapper"
    >
      <DialogTitle
        className="filterModalTitle"
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
      >
        {MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.TITLE}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent className="filterModalContent">
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.ADDRESS_STATUS}
              </Typography>
              <MultiSelect
                options={address}
                selectedValue={addressStatus}
                setSelectedValue={setAddressStatus}
                productType={true}
                placeHolderText="Select Address Status"
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="inputWrapper">
              <Typography>
                {MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.MAILED_STATUS}
              </Typography>
              <MultiSelect
                options={status}
                selectedValue={mailedStatus}
                setSelectedValue={setMailedStatus}
                productType={true}
                placeHolderText="Select Address Status"
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="mailedDate">
              <Box className="selectDate">
                <InputLabel className="dateLabel">
                  {MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.DELIVERED_DATE}
                </InputLabel>
                <KeyboardArrowDownIcon
                  sx={{
                    color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                    cursor: "pointer",
                    position: "absolute",
                    top: "38px",
                    left: "378px",
                    zIndex: "1",
                  }}
                />
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  onFocus={() => setIsDatepickerOpen(true)}
                  onBlur={() => setIsDatepickerOpen(false)}
                  placeholderText={`Select Date`}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="actionBtns">
        <Button autoFocus onClick={handleClear}>
          {MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.CANCEL_BUTTON}
        </Button>
        <Button autoFocus onClick={handleSearch}>
          {MESSAGES.ORDERS.DETAIL.CONTACTS.FILTER.SUBMIT_BUTTON}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default FilterModal;
